import styled from 'styled-components';
export const MemberWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 123, 255, 0.1);
`;
export const MemberIcon = styled.img `
  width: 28px;
  height: 28px;
  object-fit: contain;
  border-radius: 20px;
`;
export const MemberName = styled.p `
  margin: 0;
  padding: 0 6px;
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
