import styled, { css } from 'styled-components';
import ItemPicker from '@/ui/ItemPicker';
export const ItemPickerStyled = styled(ItemPicker) `
  & .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;

    & .MuiAutocomplete-input {
      padding: 15px 15px 15px 8px;
    }

    & .MuiAutocomplete-endAdornment {
      margin-top: 3px;
      right: 13px;
    }
  }
`;
export const Wrapper = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 500px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 25px;

  @media (max-width: 1000px) {
    width: 400px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 500px) {
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const Column = styled.div `
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  height: 100%;
`;
export const TitleWrapper = styled.div `
  display: flex;
  gap: 20px;
  width: 100%;
`;
export const CheckboxesContainer = styled.div `
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;

  & .MuiFormControlLabel-root {
    & .MuiButtonBase-root {
      padding: 2px;
      margin-right: 10px;
      margin-left: 4px;
    }

    & .MuiTypography-root {
      ${() => css `
        font-size: 15};
      `}
    }
  }
`;
export const Link = styled.a `
  ${({ theme }) => css `
    font-size: 14;

    color: ${theme.palette.primary.main};
    text-decoration: none;
  `}

  &:visited,
  &:hover,
  &:active,
  &:focus {
    ${({ theme }) => css `
      color: ${theme.palette.primary.main};
    `}
  }
`;
export const ErrorLabel = styled.span `
  margin-left: 10px;
  ${({ theme }) => css `
    color: ${theme.palette.error.main};
  `}
`;
export const SignUpTitle = styled.h1 `
  font-size: 30px;
  color: black;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (max-width: 600px) {
    font-size: 26px;
    width: fit-content;
    align-self: center;
  }
`;
export const CustomErrorMessage = styled.p `
  margin: 0;

  ${({ theme }) => css `
    font-size: 12px;
    color: ${theme.palette.error.main};
  `}
`;
export const ErrorMessage = styled.p `
  width: 100%;
  margin-top: 10px;
  text-align: center;
  ${({ theme }) => css `
    font-size: 14px;
    color: ${theme.palette.error.main};
  `}
`;
