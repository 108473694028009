import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Button, ListItem, Popover, Toolbar } from '@mui/material';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { yanuszLogo } from 'src/assets';
import { ArrowDownNewIcon } from 'src/assets/icons/ArrowDownNew';
import { Box } from '@mui/system';
import { CustomButton, ItemButton, ListStyled, Logo, Title } from './styled';
import Name from './ui/Name';
import SolrmHeader from './SolrmHeader';
import EnglishClassHeader from './EnglishClassHeader';
import { ROUTES, solrmRoutes } from '@/constants/routes';
import usePopoverAnchor from '@/hooks/usePopoverAnchor';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import { useAuth } from '@/hooks/useAuth';
const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};
const Header = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    const { token } = useAuth();
    const handleGoBack = () => {
        if (pathname.startsWith('/student') && token) {
            navigate(ROUTES.student.search.path);
        }
        else {
            navigate(-1);
        }
    };
    const handleSingOut = () => {
        StorageService.remove(STORAGE_KEYS.token);
        StorageService.remove(STORAGE_KEYS.enteredStudents);
        navigate(ROUTES.login.path);
        handleClose();
    };
    if ([ROUTES.login.path, ROUTES.solrmLogin.path].includes(pathname) ||
        pathname.includes(ROUTES.solrmLogin.path)) {
        return _jsx(_Fragment, {});
    }
    const isMainOrDonatePage = [
        ROUTES.main.path,
        ROUTES.donateGiverPage.path,
        ROUTES.singMacAdress.path,
        ROUTES.donatesMapPage.path,
    ].includes(pathname);
    const isMainVRPage = [
        ROUTES.student.search.path,
    ].includes(pathname);
    const isStudentTest = new RegExp(`^(${ROUTES.student.studentTest.path.replace(/:studentId|:testId/g, '\\d+')}|${ROUTES.student.studentTests.path.replace(/:studentId/g, '\\d+')})$`).test(pathname);
    const isEngtudentTest = new RegExp(`^(${ROUTES.studentEng.studentTest.path.replace(/:studentId|:testId/g, '\\d+')})|(${ROUTES.studentEng.search.path})|(${ROUTES.currentClass.path})$`).test(pathname);
    const isSolrmPage = solrmRoutes.includes(pathname);
    if (isEngtudentTest) {
        return _jsx(EnglishClassHeader, {});
    }
    if (isSolrmPage) {
        return _jsx(SolrmHeader, {});
    }
    const isVrHeader = isMainOrDonatePage || isMainVRPage;
    return (_jsx(AppBar, { sx: {
            position: 'sticky',
            backgroundColor: 'white',
            height: '60px',
            boxShadow: 'none',
        }, children: _jsxs(Toolbar, { sx: {
                height: '60px',
                minHeight: '60px !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
            }, children: [' ', isMainOrDonatePage ? (_jsx(Title, { children: pathname === ROUTES.main.path ? ('Ignite') : (_jsx(Button, { onClick: handleGoBack, children: "Ignite" })) })) : (_jsx(Button, { onClick: handleGoBack, children: _jsx(Logo, { src: yanuszLogo, alt: 'VR.Narrative' }) })), (isVrHeader || isStudentTest) && (_jsxs(Box, { sx: { flexDirection: "row", display: "flex", gap: "10px" }, children: [_jsx(Box, { id: 'print-button-portal' }), _jsxs(Box, { children: [_jsxs(CustomButton, { onClick: handleOpen, children: [_jsx(Name, {}), _jsx(ArrowDownNewIcon, {})] }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, disableScrollLock: true, children: _jsx(ListStyled, { children: _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleSingOut, children: "Sign Out" }) }) }) })] })] }))] }) }));
};
export default memo(Header);
