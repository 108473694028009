import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ControlButton, Logo } from './styled';
import Name from './Name';
import logo from '@/public/logo.jpg';
import { ROUTES } from '@/constants/routes';
const EnglishClassHeader = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleGoBack = () => {
        navigate(-1);
    };
    const handleNavigateStudent = () => {
        navigate(ROUTES.studentEng.search.path);
    };
    const handleNavigateClass = () => {
        navigate(ROUTES.currentClass.path);
    };
    return (_jsx(AppBar, { id: 'header', sx: {
            position: 'sticky',
            backgroundColor: 'white',
            boxShadow: 'none',
        }, children: _jsxs(Toolbar, { sx: {
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingTop: '2px',
            }, children: [_jsxs(Box, { sx: {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }, children: [_jsx(Button, { "aria-label": 'Go back', onClick: handleGoBack, sx: { paddingRight: 0, paddingLeft: 0, marginBottom: '5px' }, children: _jsx(Logo, { src: logo, alt: 'VR.Narrative' }) }), (pathname === ROUTES.studentEng.search.path ||
                            pathname === ROUTES.currentClass.path ||
                            pathname === ROUTES.studentEng.studentTest.path) && (_jsx(ControlButton, { "aria-label": 'Subjects', onClick: handleNavigateStudent, active: pathname === ROUTES.studentEng.search.path, children: "Student" }))] }), (pathname === ROUTES.studentEng.search.path ||
                    pathname === ROUTES.currentClass.path ||
                    pathname === ROUTES.studentEng.studentTest.path) && (_jsxs(_Fragment, { children: [_jsx(ControlButton, { "aria-label": 'Notes', onClick: handleNavigateClass, active: pathname === ROUTES.currentClass.path, children: "Current class" }), _jsx(Name, {})] }))] }) }));
};
export default memo(EnglishClassHeader);
