import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Page, ButtonContainer, ControlButton, Content } from './styled';
import SignInForm from '@/components/SignInForm';
import SignUpForm from '@/components/SignUpForm';
const Main = () => {
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
    useEffect(() => {
        document.title = 'Ignite donate';
    }, []);
    return (_jsx(Page, { children: _jsxs(Content, { children: [_jsxs(ButtonContainer, { children: [_jsx(ControlButton, { isActive: isLoginFormVisible, onClick: () => {
                                setIsLoginFormVisible(true);
                            }, children: "Sign in" }), _jsx(ControlButton, { isActive: !isLoginFormVisible, onClick: () => {
                                setIsLoginFormVisible(false);
                            }, children: "Sign up" })] }), isLoginFormVisible ? _jsx(SignInForm, {}) : _jsx(SignUpForm, {})] }) }));
};
export default Main;
