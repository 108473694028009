import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
const LinkStyled = styled(NavLink) `
  ${({ theme, $color = 'inherit', $fontWeight = 'inherit', $noRem, $fontSize = 'inherit', $underline = 'none', $inline, }) => css `
    ${$inline
    ? css `
            display: inline;
          `
    : css `
            display: flex;
            align-items: center;
            gap: 3px;
          `}
    font-size: 16};
    font-weight: ${$fontWeight};
    color:  black;
    font-size:16

    ${$underline === 'none'
    ? css `
            text-decoration: none;
          `
    : null}

    ${$underline === 'hover'
    ? css `
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          `
    : null}

    &:visited,
    &:hover,
    &:active,
    &:focus {
      color:black};
    }
  `}
`;
export default memo(LinkStyled);
