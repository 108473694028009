export const tableHeaderStyle = {
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'grey',
};
export const totalCellStyle = {
    fontWeight: 'bold',
    textAlign: 'center',
};
