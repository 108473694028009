import styled from 'styled-components';
export const ModalTitle = styled.p `
  margin: 0;
  font-weight: 'normal';
  font-size: 20px;
  line-height: 18px;
`;
export const ModalContent = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
export const SubTitle = styled.h3 `
  color: #000;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
`;
export const GroupName = styled.p `
  color: #333238;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
export const CreatedDate = styled.p `
  color: #333238;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;
export const MembersTitle = styled.h3 `
  color: #000;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
`;
export const AddUserIcon = styled.img `
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export const EditIcon = styled.img `
  width: 17px;
  height: 17px;
  cursor: pointer;
`;
export const MembersWrapper = styled.div `
  margin-top: 6px;
  display: flex;
  gap: 10px;
  max-height: 215px;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
export const GroupDetailsWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const MembersWrapperBox = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
`;
