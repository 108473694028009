import { Card, TableCell, colors } from '@mui/material';
import styled from 'styled-components';
export const Page = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100dvh - 60px);
  align-items: center;
  flex-direction: column;
  padding: 20px 40px;
  padding-top: 10px;
  gap: 20px;
`;
export const CardSection = styled(Card) `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;
export const HeatMapBoxWrapper = styled('div') `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20px 1fr;
  gap: 40px;
`;
export const WordsPerMinuteTableCell = styled(TableCell) `
  &:not(:last-child) {
    border-right: 1px solid ${colors.grey[200]};
  }
`;
export const StudentInfoDl = styled.dl `
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
export const HeatMapBackgroundImage = styled.div `
  width: 100%;
  height: 321px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${({ url }) => url};
`;
export const buttonStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
};
