import { Paper } from '@mui/material';
import styled from 'styled-components';
export const Page = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100dvh - 60px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;
export const PaperForm = styled(Paper) `
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max(315px, 40vw);
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;
