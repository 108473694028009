import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { testUserIcon } from 'src/assets';
import { Box } from '@mui/system';
import { LeftContainer, MemberIcon, MemberMail, MemberName, MemberWrapper, RemoveButton } from './styles';
export const MemberItem = ({ member, needRemoveButton, handleAddMember }) => {
    const { name, mail, imageIcon, id } = member;
    return (_jsxs(MemberWrapper, { onClick: handleAddMember, children: [_jsxs(LeftContainer, { children: [_jsx(MemberIcon, { src: testUserIcon }), _jsxs(Box, { children: [_jsx(MemberName, { children: name }), _jsx(MemberMail, { children: mail })] })] }), needRemoveButton &&
                _jsx(RemoveButton, { children: "Remove" })] }));
};
export default MemberItem;
