import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useLayoutEffect, useRef, } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainBackground } from 'src/assets';
import { BackButtonWrapper, BackgroundImage, BackgroundImageCover, HeaderTitle, ModalContainer, Page, TableHeader, } from './styled';
import { ROUTES } from '@/constants/routes';
import DateService from '@/utils/date';
import BackButton from 'src/assets/icons/BackButton';
const StudentTests = () => {
    const location = useLocation();
    const state = location.state;
    const { testDates, personId, student } = state || {};
    const inputRef = useRef(null);
    const navigate = useNavigate();
    useLayoutEffect(() => {
        inputRef.current?.focus();
    }, []);
    const handleTestNavigate = useCallback((testId) => () => {
        navigate(`${ROUTES.student.search.path}/${personId}/${testId}`);
    }, [navigate, personId]);
    const handleBack = () => {
        navigate(-1);
    };
    return (_jsxs(Page, { children: [_jsx(ModalContainer, { isActive: !!testDates.length && !!personId, children: testDates.length && personId ? (_jsxs(Box, { component: 'section', overflow: 'hidden', children: [_jsxs(TableHeader, { children: [_jsx(BackButtonWrapper, { children: _jsx(BackButton, { color: '#FFFF', handleOnClick: handleBack }) }), _jsx(HeaderTitle, { children: student?.PrivetNm + student?.FamilyNm })] }), _jsx(TableContainer, { sx: {
                                borderBottomLeftRadius: '6px',
                                borderBottomRightRadius: '6px',
                                maxHeight: '430px',
                                width: 'max(510px, 40vw)',
                                overflowX: 'hidden',
                                backgroundColor: '#fff',
                                scrollbarWidth: '20px',
                                '::-webkit-scrollbar': {
                                    height: '4px',
                                    width: '12px'
                                },
                                '::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 10px 10px #81d5ed89',
                                    border: 'solid 4px transparent',
                                },
                                '::-webkit-scrollbar-thumb': {
                                    boxShadow: "inset 0 0 10px 10px #81D5ED",
                                    border: "solid 4px transparent",
                                    borderRadius: '3px',
                                },
                            }, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { color: '#3F3F3F', backgroundColor: 'white' }, width: '70%', children: "Date" }), _jsx(TableCell, { sx: { color: '#3F3F3F', backgroundColor: 'white' }, align: 'center', children: "Time" }), _jsx(TableCell, { sx: { color: '#3F3F3F', backgroundColor: 'white' }, children: "Duration" })] }) }), _jsx(TableBody, { children: testDates.map(({ TestC, FromDate, Duration }) => {
                                            const [date, time] = DateService.getFullDate(new Date(FromDate));
                                            return (_jsxs(TableRow, { sx: {
                                                    cursor: 'pointer',
                                                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                                                }, hover: true, onClick: handleTestNavigate(TestC), children: [_jsx(TableCell, { sx: { maxWidth: '100px' }, color: '#3F3F3F', children: date }), _jsx(TableCell, { sx: { minWidth: '200px' }, color: '#3F3F3F', align: 'center', children: time }), _jsx(TableCell, { sx: { minWidth: '500px' }, color: '#3F3F3F', children: DateService.formatDuration(Duration) })] }, TestC));
                                        }) })] }) })] })) : null }), _jsx(BackgroundImageCover, {}), _jsx(BackgroundImage, { alt: 'background', src: mainBackground })] }));
};
export default memo(StudentTests);
