import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        return hasError ? _jsx("h1", { children: "Sorry, an error has occurred" }) : children;
    }
}
