/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line import/no-extraneous-dependencies
import { object, string } from 'zod';
export const registerScheme = object({
    username: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    email: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .email('Email is not valid')
        .transform((value) => value.trim()),
    password: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    confirm: string()
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    country: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    phone: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
});
export const defaultValues = {
    username: '',
    confirm: '',
    email: '',
    password: '',
    country: undefined,
    phone: '',
    terms: false,
    emailsReceive: false,
    interestedIn: undefined,
};
