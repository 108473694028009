import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useStudentInfoApi } from './useStudentInfoApi';
import { ROUTES } from '@/constants/routes';
export const useStudentInfo = () => {
    const [loading, setLoading] = useState(false);
    const [studentData, setStudentData] = useState(null);
    const { testId } = useParams();
    const navigate = useNavigate();
    const { getStudentQuestions } = useStudentInfoApi();
    const getStudentData = useCallback(async (testId) => {
        setLoading(true);
        const questions = await getStudentQuestions(testId);
        const data = {
            questions: questions.data,
        };
        setStudentData(data);
        setLoading(false);
    }, []);
    useEffect(() => {
        if (!testId) {
            navigate(ROUTES.student.search.path);
            return;
        }
        getStudentData(testId);
    }, [navigate, testId, getStudentData]);
    return { studentData, loading };
};
