import styled from 'styled-components';
export const Page = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;
export const ButtonContainer = styled.div `
  display: flex;
  margin-bottom: 20px;
`;
export const ControlButton = styled.button `
  margin: 0 10px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #333;
  outline: none;
  position: relative;
  font-size: 25px;
  font-weight: bold;
  opacity: ${(props) => (props.isActive ? '1' : '0.7')};
  transition: opacity 0.3s ease;

  &:hover {
    color: #007bff;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: #007bff;
    opacity: ${(props) => (props.isActive ? '1' : '0')};

    transition: opacity 0.3s ease;
  }

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;
export const Content = styled.div `
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 500px) {
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
