import { Paper } from '@mui/material';
import styled from 'styled-components';
export const Page = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100dvh - 60px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;
export const ModalContainer = styled.div `
  position: absolute;
  left: 50%;
  top: ${({ isActive }) => (isActive ? '50%' : 'calc(50% - 30px)')};
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const BackgroundImage = styled.img `
  position: absolute;
  top: 0px;
  z-index: -2;

  object-fit: cover;
  width: 100%;
  height: 100%;
`;
export const BackgroundImageCover = styled.div `
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  z-index: -1;

  background-color: #81d5ed;
  opacity: 80%;
`;
export const Title = styled.h2 `
  margin: 0;
  margin-bottom: 12px;

  color: #1a141f;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Poppins';
`;
export const TableHeader = styled.div `
  box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;

  background-color: #81d5ed;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
`;
export const BackButtonWrapper = styled.div `
  position: absolute;
  left: 10px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderTitle = styled.h2 `
  margin: 0;
  color: #ffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Poppins';
`;
export const PaperForm = styled(Paper) `
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max(315px, 40vw);
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;
