import styled from 'styled-components';
export const Logo = styled.img `
  max-height: 50px;
  max-width: 150px;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
export const Title = styled.h1 `
  color: black;
  font-size: 25;
  text-align: center;
  margin: 0;
  padding: 0;
  text-transform: none;
`;
export const ControlButton = styled.button `
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  height: 100%;
  background-color: ${({ active }) => (active ? 'white' : 'lightgray')};
  align-self: center;
  color: black;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border: 1px solid black;

  &:hover {
    background-color: ${({ active }) => (active ? 'white' : 'lightgray')};
  }

  &:active {
    background-color: darkgray;
  }
`;
