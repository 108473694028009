import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useCallback, useState } from 'react';
import Modal from 'react-modal';
import debounce from 'lodash/debounce';
import { useUsers } from 'src/api/hooks/useUsers';
import { groupCloseIcon } from 'src/assets';
import { ModalContent, SaveButton, MembersForm } from './styled';
import ChosenMember from './ui/ChosenMember';
import { MemberItem } from '../../ui/MemberItem';
import { ChosenMemberWrapper, CloseButton, CloseImage, DropListInputField, InputLabel, InputWrapper, ListContainer, ListWrapper, MembersWrapper, Spinner, SpinnerContainer, Title } from '../../globalStyled';
export default function MembersEditModal({ modalIsOpen, setModalIsOpen, }) {
    const [searchValue, setSearchValue] = useState('');
    const [chosenMembers, setChosenMembers] = useState([]);
    const { data, setSearchString, isFetching, loadMore } = useUsers(' ');
    const membersWrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [modalHeight, setModalHeight] = useState('250px');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (modalIsOpen && inputRef.current) {
                inputRef.current.focus();
            }
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [modalIsOpen]);
    const handleInputFocus = () => {
        setIsInputFocused(true);
        setModalHeight('380px');
    };
    const handleInputBlur = () => {
        setIsInputFocused(false);
        setModalHeight('250px');
    };
    const handleCloseModal = () => {
        setModalIsOpen(false);
        handleInputBlur();
        setChosenMembers([]);
    };
    const clearInput = () => {
        setSearchValue('');
        setSearchString('');
    };
    const handleScroll = () => {
        const wrapper = membersWrapperRef.current;
        if (wrapper) {
            const bottom = wrapper.scrollHeight - wrapper.scrollTop === wrapper.clientHeight;
            if (bottom && !isFetching) {
                loadMore();
            }
        }
    };
    const handleAddMember = (member) => {
        setChosenMembers((prevMembers) => [...prevMembers, member]);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const availableMembers = data.filter((member) => !chosenMembers.some((chosen) => chosen.id === member.id.toString()));
    const debouncedSetSearchString = useCallback(debounce((value) => {
        setSearchString(value);
    }, 500), []);
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
        debouncedSetSearchString(e.target.value);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && searchValue === '' && chosenMembers.length > 0) {
            setChosenMembers((prevMembers) => prevMembers.slice(0, -1));
        }
    };
    const renderMembers = () => (_jsxs(MembersWrapper, { onScroll: handleScroll, ref: membersWrapperRef, isFocused: isInputFocused, children: [availableMembers.map((member, index) => (_jsx(MemberItem, { member: {
                    name: member.name,
                    mail: member.email,
                    groupId: '1',
                    imageIcon: '',
                    id: member.id.toString(),
                }, handleAddMember: () => handleAddMember({
                    name: member.name,
                    mail: member.email,
                    groupId: '1',
                    imageIcon: '',
                    id: member.id.toString(),
                }) }, index))), isFetching && (_jsx(SpinnerContainer, { children: _jsx(Spinner, {}) }))] }));
    return (_jsx(Modal, { isOpen: modalIsOpen, style: {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                position: 'absolute',
                top: '20%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '508px',
                height: modalHeight,
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '32px',
                transition: 'height 0.3s ease',
            },
        }, onRequestClose: handleCloseModal, children: _jsxs(ModalContent, { children: [_jsx(CloseButton, { onClick: handleCloseModal, children: _jsx(CloseImage, { src: groupCloseIcon }) }), _jsx(Title, { children: "Add members" }), _jsxs(MembersForm, { onSubmit: () => {
                        handleInputBlur();
                    }, children: [_jsxs(ListContainer, { children: [_jsx(InputLabel, { children: "Add members" }), _jsxs(ListWrapper, { onFocus: handleInputFocus, hasError: false, isFocused: isInputFocused, children: [_jsxs(InputWrapper, { isFocused: isInputFocused, children: [_jsx(ChosenMemberWrapper, { children: chosenMembers.map((member) => _jsx(ChosenMember, { member: member }, member.id)) }), _jsx(DropListInputField, { placeholder: chosenMembers.length === 0 ? 'Member' : '', onChange: handleSearchChange, onKeyDown: handleKeyDown, value: searchValue, ref: inputRef }), searchValue.length !== 0 && (_jsx(CloseImage, { src: groupCloseIcon, onClick: clearInput }))] }), renderMembers()] })] }), _jsx(SaveButton, { type: "submit", children: "Add" })] })] }) }));
}
