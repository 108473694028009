import RequestService from '@/utils/request';
import { axiosInstanceSolrm } from '@/env/http';
export const login = async ({ email, password }) => {
    try {
        const response = await axiosInstanceSolrm.post('/api/VR/ApiWebCall', {
            p: RequestService.buildRequest('Web_GiverLogIn', email, password),
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
        return undefined;
    }
};
