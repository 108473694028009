import styled from 'styled-components';
export const MemberWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  &:hover button {
    opacity: 1;
    visibility: visible;
  }
`;
export const LeftContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
export const MemberIcon = styled.img `
  width: 46px;
  height: 46px;
  object-fit: contain;
  border-radius: 100px;
`;
export const MemberName = styled.p `
  margin: 0;
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
`;
export const MemberMail = styled.p `
  margin: 0;
  color: var(--Placeholders, #8f8f8f);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
export const RemoveButton = styled.button `
  border: none;
  background-color: transparent;
  color: var(--IQ-Main, #007bff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  border-radius: 5px;
  padding: 3px;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.1s ease-in-out,
    background-color 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    visibility: visible;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
    background-color: rgba(0, 123, 255, 0.1);
  }
`;
