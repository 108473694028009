const buildQueryParams = (...args) => {
    const queryParams = args.map((arg) => `~${arg}`);
    return queryParams.join('');
};
const buildRequest = (path, ...args) => `${path}${buildQueryParams(...args)}`;
const RequestService = {
    buildQueryParams,
    buildRequest,
};
export default RequestService;
