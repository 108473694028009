import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'react-modal';
import { addUserIcon, boldEditIcon, groupCloseIcon } from 'src/assets';
import { format } from 'date-fns';
import { useState } from 'react';
import { ModalContent, SubTitle, GroupName, CreatedDate, MembersTitle, AddUserIcon, EditIcon, MembersWrapper, GroupDetailsWrapper, MembersWrapperBox, } from './styled';
import { CloseButton, CloseImage, Title } from '@/pages/IQNarative/globalStyled';
import GroupEditModal from '@/pages/IQNarative/forms/GroupEditModal';
import MembersEditModal from '@/pages/IQNarative/forms/MembersEditModal';
import MemberItem from '@/pages/IQNarative/ui/MemberItem';
export default function GroupModal({ modalIsOpen, setModalIsOpen, subjectName, date, members, }) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editMemberModlaOpen, setEditMemberModalOpen] = useState(false);
    const handleCloseModal = () => setModalIsOpen(false);
    const handleEditGroupClick = (e) => {
        e.stopPropagation();
        setEditModalOpen(true);
    };
    const handleEditMembersClick = (e) => {
        e.stopPropagation();
        setEditMemberModalOpen(true);
    };
    const renderMembers = () => (_jsx(MembersWrapper, { children: members.map((member, index) => (_jsx(MemberItem, { member: {
                name: "Name",
                mail: member,
                groupId: "1",
                imageIcon: "",
                id: "1",
            } }, index))) }));
    const renderGroupDetails = () => (_jsxs(_Fragment, { children: [_jsxs(GroupDetailsWrapper, { children: [_jsx(SubTitle, { children: "Group Name" }), _jsx(EditIcon, { src: boldEditIcon, alt: "Edit", onClick: handleEditGroupClick })] }), _jsx(GroupName, { children: subjectName }), _jsxs(CreatedDate, { children: ["Created ", format(new Date(date), 'dd/MM/yyyy HH:mm')] }), _jsxs(MembersWrapperBox, { children: [_jsxs(MembersTitle, { children: ["Members ", members.length ? `(${members.length})` : ""] }), _jsx(AddUserIcon, { src: addUserIcon, alt: "Add user", onClick: handleEditMembersClick })] })] }));
    return (_jsxs(_Fragment, { children: [!(editModalOpen || editMemberModlaOpen) && (_jsx(Modal, { isOpen: modalIsOpen, style: {
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                        position: 'absolute',
                        top: '20%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '508px',
                        height: '432px',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        padding: '32px',
                    },
                }, onRequestClose: handleCloseModal, children: _jsxs(ModalContent, { children: [_jsx(CloseButton, { onClick: handleCloseModal, children: _jsx(CloseImage, { src: groupCloseIcon }) }), _jsx(Title, { children: "Group Info" }), renderGroupDetails(), renderMembers()] }) })), editModalOpen && _jsx(GroupEditModal, { modalIsOpen: editModalOpen, setModalIsOpen: setEditModalOpen, subjectId: '1', subjectName: subjectName }), editMemberModlaOpen && _jsx(MembersEditModal, { modalIsOpen: editMemberModlaOpen, setModalIsOpen: setEditMemberModalOpen, subjectId: '1', subjectName: subjectName })] }));
}
