import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import DataGrid from 'react-data-grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from 'styled-components';
import { cellExpandClassname } from './styled';
import { createRows, rowKeyGetter } from './helpers';
export function CellExpanderFormatter({ tabIndex, expanded, onCellExpand, }) {
    function handleKeyDown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }
    return (_jsx("div", { className: cellExpandClassname, children: _jsx("span", { onClick: onCellExpand, onKeyDown: handleKeyDown, children: _jsx("span", { tabIndex: tabIndex, children: expanded ? '\u25BC' : '\u25B6' }) }) }));
}
function SubDataGrid({ donate }) {
    const gridRef = useRef(null);
    const subDataGridColumns = [
        { key: 'countryName', name: 'Country name' },
        { key: 'accountNo', name: 'Account No' },
        { key: 'customerName', name: 'Customer name' },
    ];
    return (_jsx(DataGrid, { ref: gridRef, rows: [donate], columns: subDataGridColumns, rowKeyGetter: rowKeyGetter, style: { blockSize: 100 } }));
}
export default function MobileDonateDataGrid({ data }) {
    const columns = useMemo(() => [
        {
            key: 'expanded',
            name: '',
            minWidth: 30,
            width: 30,
            colSpan(args) {
                return args.type === 'ROW' && args.row.type === 'DETAIL' ? 3 : undefined;
            },
            cellClass(row) {
                return row.type === 'DETAIL'
                    ? css `
                padding: 24px;
              `
                    : undefined;
            },
            renderCell({ row, tabIndex, onRowChange }) {
                if (row.type === 'DETAIL') {
                    return _jsx(SubDataGrid, { donate: data[row.parentId - 1] });
                }
                return (_jsx(CellExpanderFormatter, { expanded: row.expanded, tabIndex: tabIndex, onCellExpand: () => {
                        onRowChange({ ...row, expanded: !row.expanded });
                    } }));
            },
        },
        { key: 'id', name: 'ID', width: 35 },
        { key: 'date', name: 'Date' },
    ], [data]);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setRows(createRows(data));
    }, [data]);
    function onRowsChange(changebleRows, { indexes }) {
        const row = changebleRows[indexes[0]];
        if (row.type === 'MASTER') {
            if (row.expanded) {
                changebleRows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: row.id + 100,
                    parentId: row.id,
                });
            }
            else {
                changebleRows.splice(indexes[0] + 1, 1);
            }
            setRows(changebleRows);
        }
    }
    return (_jsx(DataGrid, { rowKeyGetter: rowKeyGetter, columns: columns, rows: rows, onRowsChange: onRowsChange, headerRowHeight: 45, rowHeight: (row) => (row.type === 'DETAIL' ? 70 : 45), className: 'fill-grid', enableVirtualization: false, onCellKeyDown: (_, event) => {
            if (event.isDefaultPrevented()) {
                event.preventGridDefault();
            }
        } }));
}
