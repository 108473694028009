import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { ArrowDownIcon, CheckboxRectangleCheckedIcon, CheckboxRectangleIcon, LocationIcon, LockIcon, SmsIcon, UserThinIcon, } from 'src/assets/icons';
import { signUp } from 'src/api/signUp';
import { useSingUpCountryes } from 'src/api/hooks/useSingUpCountryes';
import { SignUpFormWrapper, Column, CheckboxesContainer, ErrorLabel, Link, SignUpTitle, ItemPickerStyled, ErrorMessage, } from './styled';
import { defaultValues, registerScheme } from './lib/validation';
import { ROUTES } from '@/constants/routes';
import InputFormField from '@/ui/InputFormField';
import SubmitButton from '@/ui/SubmitButton';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
const SignUpForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passwordsNotMatching, setPasswordsNotMatching] = useState('');
    const [emailAlreadyExists, setEmailAlreadyExists] = useState('');
    const { countries } = useSingUpCountryes();
    const [errorMessage, setErrorMessage] = useState('');
    const { handleSubmit, control, formState: { errors, isValid },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
     } = useForm({
        defaultValues,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        resolver: zodResolver(registerScheme),
    });
    const submit = useCallback(async (data) => {
        if (!isValid)
            return;
        if (data.password !== data.confirm) {
            setPasswordsNotMatching('Passwords are not matching');
            return;
        }
        setLoading(true);
        const response = await signUp({
            ...data,
        });
        setLoading(false);
        if (response && response.toString() !== '0' && !isAxiosError(response)) {
            StorageService.set(STORAGE_KEYS.userId, response);
            navigate(ROUTES.donateGiverPage.path);
        }
        else if (response && response.toString() === '0') {
            setEmailAlreadyExists('User with this email already exists');
        }
        else {
            setErrorMessage('Unhandleable error');
        }
    }, [isValid, navigate]);
    const loaderId = useId();
    return (_jsxs(SignUpFormWrapper, { onSubmit: handleSubmit(submit), "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: [_jsx(SignUpTitle, { children: "Create new account" }), _jsxs(Column, { children: [_jsx(Controller, { name: 'username', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: UserThinIcon, error: !!errors.username, helperText: errors.username?.message, autoComplete: 'name', placeholder: 'Name', autoFocus: true })) }), _jsx(Controller, { name: 'email', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: SmsIcon, onChange: (event) => {
                                if (emailAlreadyExists) {
                                    setEmailAlreadyExists('');
                                }
                                onChange(event);
                            }, error: !!errors.email || !!emailAlreadyExists, helperText: errors.email?.message ?? emailAlreadyExists, autoComplete: 'email', placeholder: 'Email' })) }), _jsx(Controller, { name: 'phone', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, onChange: (event) => {
                                if (emailAlreadyExists) {
                                    setEmailAlreadyExists('');
                                }
                                onChange(event);
                            }, error: !!errors.phone, helperText: errors.phone?.message, autoComplete: 'phone', placeholder: 'Phone' })) }), _jsx(Controller, { name: 'password', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, onChange: (event) => {
                                if (passwordsNotMatching) {
                                    setPasswordsNotMatching('');
                                }
                                onChange(event);
                            }, error: !!errors.password || !!passwordsNotMatching, autoComplete: 'new-password', placeholder: 'Password', helperText: errors.password?.message ?? passwordsNotMatching ?? 'Must be at least 8 characters.' })) }), _jsx(Controller, { name: 'confirm', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: LockIcon, onChange: (event) => {
                                if (passwordsNotMatching) {
                                    setPasswordsNotMatching('');
                                }
                                onChange(event);
                            }, type: 'password', error: !!errors.confirm || !!passwordsNotMatching, autoComplete: 'new-password', placeholder: 'Repeat password', helperText: errors.confirm?.message ?? passwordsNotMatching })) }), _jsx(Controller, { name: 'country', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(ItemPickerStyled, { inputRef: ref, fullWidth: true, popupIcon: _jsx(ArrowDownIcon, {}), onAutoCompleteChange: onChange, onChange: onChange, error: !!errors.country, dataOptions: countries, helperText: errors.country?.message, placeholder: 'Country', Icon: LocationIcon, ...field })) })] }), _jsxs(CheckboxesContainer, { children: [_jsx(FormControlLabel, { label: 'I want to receive special offers and promotional emails', control: _jsx(Controller, { name: 'emailsReceive', control: control, render: ({ field: { ref, ...field } }) => (_jsx(Checkbox, { ...field, inputRef: ref, icon: _jsx(CheckboxRectangleIcon, {}), checkedIcon: _jsx(CheckboxRectangleCheckedIcon, {}) })) }) }), _jsx(FormControlLabel, { label: _jsxs(_Fragment, { children: ["I have read and agree to ", _jsx(Link, { target: '_blank', children: "Terms of use" }), " and", ' ', _jsx(Link, { target: '_blank', children: "Privacy Policy" }), errors.terms ? _jsx(ErrorLabel, { children: errors.terms?.message }) : null] }), control: _jsx(Controller, { name: 'terms', control: control, render: ({ field: { ref, ...field } }) => (_jsx(Checkbox, { ...field, inputRef: ref, icon: _jsx(CheckboxRectangleIcon, { color: errors.terms ? 'error' : undefined }), checkedIcon: _jsx(CheckboxRectangleCheckedIcon, {}) })) }) })] }), _jsx(SubmitButton, { loaderId: loaderId, loading: loading, width: 292, height: 48, fontWeight: 900, "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: "Create account" }), errorMessage ? _jsx(ErrorMessage, { children: errorMessage }) : null] }));
};
export default memo(SignUpForm);
