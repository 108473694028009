import { axiosInstanceSolrm } from '@/env/http';
import RequestService from '@/utils/request';
export async function signUp({ username, email, phone, password, country, }) {
    try {
        const { data } = await axiosInstanceSolrm.post('/api/VR/ApiWebCall', {
            p: RequestService.buildRequest('Web_AddNewGiver', username, email, phone, password, country),
        });
        return data;
    }
    catch (error) {
        console.error(error);
        return undefined;
    }
}
