import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useAddNote } from 'src/api/hooks/useAddNotes';
import { ControlButton } from './styles';
import { Spinner } from '@/pages/IQNarative/globalStyled';
export const FormSubmitButton = ({ handleSecsess, subjectId }) => {
    const { mutate, isPending } = useAddNote();
    const { reset, handleSubmit } = useFormContext();
    const handleFormSubmit = ({ text }) => {
        mutate({ text, subjectId: Number(subjectId) }, {
            onSuccess: () => {
                handleSecsess();
                reset({
                    text: '',
                });
            },
        });
    };
    if (isPending)
        return _jsx(Spinner, {});
    return _jsx(ControlButton, { onClick: handleSubmit(handleFormSubmit), children: "+" });
};
