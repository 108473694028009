import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, CircularProgress, TextField, Typography, useTheme } from '@mui/material';
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { Page, PaperForm } from './styled';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import { ROUTES } from '@/constants/routes';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
const loginRequest = async (email, password) => {
    const response = await axiosInstance.post('ApiCall', {
        p: RequestService.buildRequest('BO_User_LogIn_Password', email, password),
    });
    if (response.data === -1) {
        throw new Error('Invalid email or password');
    }
    return response.data[0].Token;
};
const LoginEnglishClass = () => {
    const { control, handleSubmit, formState: { errors }, setError, setFocus, } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    useEffect(() => {
        document.title = 'English class';
    }, []);
    const invalidToken = location.state?.invalidToken;
    const { mutate, isPending } = useMutation({
        mutationFn: (data) => loginRequest(data.email, data.password),
        onSuccess: (token) => {
            StorageService.set(STORAGE_KEYS.token, token);
            if (invalidToken) {
                window.history.replaceState({}, document.title);
            }
            navigate(ROUTES.studentEng.search.path);
        },
        onError: () => {
            setError('email', { message: 'Invalid email or password' });
            setError('password', { message: 'Invalid email or password' });
        },
    });
    const onSubmit = (data) => {
        mutate(data);
    };
    useEffect(() => {
        if (invalidToken) {
            StorageService.remove(STORAGE_KEYS.token);
        }
        setFocus('email');
    }, [invalidToken, setFocus]);
    return (_jsx(Page, { children: _jsx("section", { children: _jsx(PaperForm, { component: 'form', elevation: 5, onSubmit: handleSubmit(onSubmit), children: _jsxs(_Fragment, { children: [_jsx(Box, { width: '100%', children: _jsx(Typography, { variant: 'h4', children: "Welcome" }) }), _jsxs(Box, { sx: {
                                border: `1px solid ${theme.palette.primary.main}`,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                borderRadius: '6px',
                                padding: '10px',
                            }, children: [_jsx(Controller, { name: 'email', control: control, rules: { required: 'Required field' }, render: ({ field }) => (_jsx(TextField, { label: 'Email', type: 'email', variant: 'filled', fullWidth: true, error: !!errors.email, helperText: errors.email?.message, ...field })) }), _jsx(Controller, { name: 'password', control: control, rules: { required: 'Required field' }, render: ({ field }) => (_jsx(TextField, { label: 'Password', type: 'password', variant: 'filled', fullWidth: true, error: !!errors.password, helperText: errors.password?.message, ...field })) })] }), _jsx(Button, { type: 'submit', disabled: isPending, variant: 'contained', sx: { minWidth: '100px' }, children: isPending ? _jsx(CircularProgress, { size: 22, color: 'inherit' }) : 'Login' })] }) }) }) }));
};
export default memo(LoginEnglishClass);
