import styled from 'styled-components';
import ItemPicker from '@/ui/ItemPicker';
export const StyledSelectContainer = styled.div `
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    font-weight: bold;
  }

  select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
  }
`;
export const ModalTitle = styled.h2 `
  margin: 0;
`;
export const ModalContent = styled.div ``;
export const ItemPickerStyled = styled(ItemPicker) `
  & .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;

    & .MuiAutocomplete-input {
      padding: 15px 15px 15px 8px;
    }

    & .MuiAutocomplete-endAdornment {
      margin-top: 3px;
      right: 13px;
    }
  }
`;
export const DonateFormWrapper = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`;
export const HeaderConatiner = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
export const CloseButton = styled.button `
  border: none;
  background-color: transparent;
`;
export const CloseImage = styled.img `
  height: 20px;
  width: 20px;
`;
