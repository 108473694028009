const padFormat = (n) => n.toString().padStart(2, '0');
const getFullDate = (date) => {
    const fullDate = `${padFormat(date.getDate())}.${padFormat(date.getMonth() + 1)}.${date.getFullYear()}`;
    const time = `${padFormat(date.getHours())}:${padFormat(date.getMinutes())}`;
    return [fullDate, time];
};
const formatFullDate = (date, separator) => {
    const [fullDate, time] = getFullDate(date);
    return `${fullDate}${separator ? ` ${separator} ` : ' '}${time}`;
};
const formatDuration = (durationSeconds) => {
    const durationMinutes = Math.floor(durationSeconds / 60);
    return durationSeconds < 60
        ? 'less than 1 minute'
        : `${durationMinutes} minute${durationMinutes > 1 ? 's' : ''}`;
};
const DateService = {
    padFormat,
    formatFullDate,
    formatDuration,
    getFullDate,
};
export default DateService;
