import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ListItem } from '@mui/material';
import { memo, useState } from 'react';
import { AutocompleteStyled, PaperStyled } from './styled';
import InputFormField from '../InputFormField';
const ItemPicker = ({ value, popupIcon, fullWidth, onAutoCompleteChange, disabled, TextField = InputFormField, defaultValue, dataOptions, disableFilter, loading, ...props }) => {
    const [openCountriesList, setOpenCountriesList] = useState(false);
    const handleOpen = () => {
        setOpenCountriesList(true);
    };
    const handleClose = () => {
        setOpenCountriesList(false);
    };
    const handleChange = (_, data) => {
        onAutoCompleteChange?.(data?.id);
    };
    return (_jsx(AutocompleteStyled, { popupIcon: popupIcon, onOpen: handleOpen, onClose: handleClose, onChange: handleChange, open: dataOptions.length === 0 ? false : openCountriesList, disabled: disabled, isOptionEqualToValue: (option, optValue) => option.name === optValue.name, getOptionLabel: (option) => option.name, filterOptions: disableFilter ? (options) => options : undefined, options: dataOptions, renderOption: (renderProps, option) => (_jsx(ListItem, { ...renderProps, children: `${option.name}` })), PaperComponent: PaperStyled, renderInput: (params) => (_jsx(TextField, { ...props, value: value, ...params, fullWidth: fullWidth, disabled: disabled, InputProps: {
                ...params.InputProps,
                endAdornment: _jsx(_Fragment, { children: params.InputProps.endAdornment }),
            } })), defaultValue: defaultValue }));
};
export default memo(ItemPicker);
