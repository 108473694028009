import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetUserNameEng } from 'src/api/hooks/useGetUserNameEng';
import { ROUTES } from '@/constants/routes';
const Name = () => {
    const { pathname } = useLocation();
    const { name } = useGetUserNameEng();
    return pathname !== ROUTES.login.path && pathname !== ROUTES.main.path ? (_jsx(Typography, { color: 'grey', variant: 'h6', fontWeight: 600, sx: { borderLeft: '3px solid black', paddingLeft: '10px' }, children: name })) : null;
};
export default memo(Name);
