import { Button } from '@mui/material';
import { memo } from 'react';
import styled, { css } from 'styled-components';
const ButtonCustomStyled = styled(Button) `
  width: 100%;

  ${({ theme, $maxWidth, $maxHeight, $textColor, $width, $height, $fontSize, $fontWeight, $borderRadius, }) => css `
    font-size: ${typeof $fontSize === 'number' ? theme.util.pxToRem($fontSize) : $fontSize};

    ${$borderRadius
    ? css `
          border-radius: ${$borderRadius};
        `
    : null}

    ${$fontWeight
    ? css `
          font-weight: ${$fontWeight};
        `
    : null}

    ${$maxWidth
    ? css `
          max-width: ${typeof $maxWidth === 'number' ? `${$maxWidth}px` : $maxWidth};
        `
    : null}

    ${$maxHeight
    ? css `
          max-height: ${typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight};
        `
    : null}

    ${$width
    ? css `
          width: ${typeof $width === 'number' ? `${$width}px` : $width};
        `
    : null}

    ${$height
    ? css `
          height: ${typeof $height === 'number' ? `${$height}px` : $height};
        `
    : null}

    ${$textColor
    ? css `
          color: ${theme.palette[$textColor].main};
        `
    : null}
  `}
`;
export default memo(ButtonCustomStyled);
