export const ROUTES = {
    solrmLogin: {
        path: '/solrmLogin',
    },
    login: {
        path: '/login',
    },
    main: {
        path: '/main',
    },
    student: {
        search: {
            path: '/students',
        },
        studentTests: {
            path: '/students/:studentId/student-tests',
        },
        studentTest: {
            path: '/students/:studentId/:testId',
        },
    },
    studentEng: {
        search: {
            path: '/studentEng',
        },
        studentTest: {
            path: '/studentEng/:studentId/:testId',
        },
    },
    dataGridPage: {
        path: '/data-grid-page',
    },
    currentClass: {
        path: '/current-class',
    },
    donateGiverPage: {
        path: '/donate-giver',
    },
    donatesMapPage: {
        path: '/donates-map',
    },
    singMacAdress: {
        path: '/sing-mac-address',
    },
    engLogin: {
        path: '/Eng/Login',
    },
    engStudent: {
        path: '/Eng/student',
    },
    subjects: {
        path: '/subjects',
    },
    notes: {
        path: '/notes',
    },
    users: {
        path: '/users',
    },
};
export const solrmRoutes = [ROUTES.subjects.path, ROUTES.notes.path, ROUTES.users.path];
