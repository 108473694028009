import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useLayoutEffect, useRef, useState, } from 'react';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { Page, PaperForm } from './styled';
import { ROUTES } from '@/constants/routes';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
import theme from '@/theme/index';
import DateService from '@/utils/date';
const StudentSearchEng = () => {
    const [loading, setLoading] = useState(false);
    const [personId, setPersonId] = useState('');
    const [testDates, setTestDates] = useState([]);
    const [student, setStudent] = useState(null);
    const [proceedDisabled, setProceedDisabled] = useState(false);
    const inputRef = useRef(null);
    const [personIdError, setPersonIdError] = useState('');
    const navigate = useNavigate();
    const handlePersonIdChange = useCallback((event) => {
        setPersonId(event.target.value);
        setPersonIdError('');
        if (testDates.length) {
            setTestDates([]);
        }
        if (proceedDisabled) {
            setProceedDisabled(false);
        }
    }, [testDates, proceedDisabled]);
    useLayoutEffect(() => {
        inputRef.current?.focus();
    }, []);
    const validate = useCallback(async () => {
        if (!personId) {
            setPersonIdError('Required field');
            inputRef.current?.focus();
            return;
        }
        setLoading(true);
        setTestDates([]);
        setProceedDisabled(true);
        const token = StorageService.get(STORAGE_KEYS.token, false);
        try {
            const person = await axiosInstance.post('ApiCall', {
                p: RequestService.buildRequest('Eng_BO_Get_Student', token, personId),
            });
            if (!person?.data || !Array.isArray(person.data)) {
                setPersonIdError('Student with this ID does not exist');
                setLoading(false);
                return;
            }
            setStudent(person.data[0]);
            const personDates = await axiosInstance.post('ApiCall', {
                p: RequestService.buildRequest('Eng_BO_Get_Student_Dates', token, personId),
            });
            setTestDates(personDates.data);
        }
        catch (error) {
            if (isAxiosError(error)) {
                setPersonIdError('Student with this ID does not exist');
            }
            else {
                setPersonIdError('An unexpected error occurred');
            }
        }
        setLoading(false);
        inputRef.current?.focus();
    }, [personId]);
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            validate();
        }
    }, [validate]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        validate();
    };
    const handleTestNavigate = useCallback((testId) => () => {
        navigate(`${ROUTES.studentEng.search.path}/${personId}/${testId}`);
    }, [navigate, personId]);
    return (_jsxs(Page, { children: [_jsxs(PaperForm, { component: 'form', elevation: 5, onSubmit: handleSubmit, sx: { marginBottom: testDates.length && personId ? 0 : '200px' }, children: [_jsx(Box, { sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }, children: _jsx(Typography, { variant: 'h5', children: "Choose student" }) }), _jsx(Box, { sx: {
                            border: `1px solid ${theme.palette.primary.main}`,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            borderRadius: '6px',
                            padding: '10px',
                        }, children: _jsx(TextField, { label: 'ID', value: personId, inputRef: inputRef, type: 'text', variant: 'filled', onChange: handlePersonIdChange, onKeyDown: handleKeyDown, fullWidth: true, error: !!personIdError.length, helperText: personIdError }) }), _jsx(Tooltip, { arrow: true, placement: 'bottom', title: proceedDisabled && testDates.length ? 'Change ID to proceed' : '', children: _jsx("span", { children: _jsx(Button, { type: 'submit', disabled: loading || proceedDisabled, variant: 'contained', sx: { minWidth: '150px' }, children: loading ? _jsx(CircularProgress, { size: 22, color: 'inherit' }) : 'Proceed' }) }) })] }), testDates.length && personId ? (_jsxs(Box, { component: 'section', overflow: 'hidden', children: [_jsx(Typography, { variant: 'h6', fontWeight: 600, textAlign: 'center', padding: '4px 0', children: student?.Nm }), _jsx(TableContainer, { sx: {
                            border: '1px solid rgb(224, 224, 224)',
                            borderRadius: '6px',
                            maxHeight: '350px',
                            width: 'max(315px, 40vw)',
                            overflowX: 'hidden',
                        }, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { fontWeight: 600, color: 'white', backgroundColor: 'grey' }, width: '20%', children: "Date" }), _jsx(TableCell, { sx: { fontWeight: 600, color: 'white', backgroundColor: 'grey' }, align: 'center', width: '20%', children: "Time" }), _jsx(TableCell, { sx: { fontWeight: 600, color: 'white', backgroundColor: 'grey' }, align: 'center', children: "Steps" }), _jsx(TableCell, { sx: { fontWeight: 600, color: 'white', backgroundColor: 'grey' }, align: 'center', children: "Duration(minutes)" })] }) }), _jsx(TableBody, { children: testDates.map(({ TestC, FromDate, Steps, Duration }) => {
                                        const [date, time] = DateService.getFullDate(new Date(FromDate));
                                        return (_jsxs(TableRow, { sx: {
                                                cursor: 'pointer',
                                                '&:last-child td, &:last-child th': { borderBottom: 0 },
                                            }, hover: true, onClick: handleTestNavigate(TestC), children: [_jsx(TableCell, { sx: { maxWidth: '50px' }, children: date }), _jsx(TableCell, { sx: { maxWidth: '100px' }, align: 'center', children: time }), _jsx(TableCell, { sx: { minWidth: '100px' }, align: 'center', children: Steps }), _jsx(TableCell, { sx: { minWidth: '100px' }, align: 'center', children: Duration })] }, TestC));
                                    }) })] }) })] })) : null] }));
};
export default memo(StudentSearchEng);
