import { jsx as _jsx } from "react/jsx-runtime";
import { memo, forwardRef } from 'react';
import ArrowDownIcon from 'src/assets/icons/ArrowDown';
import { FieldAdornment, InputFieldStyled } from './styled';
import theme from '../../lib/theme';
const InputFormField = forwardRef(({ children, padding, Icon, InputProps, SelectProps, autoComplete, borderColor, multiline, needSeparateLine = true, ...props }, ref) => (_jsx(InputFieldStyled, { ...props, disableFieldset: true, borderColor: borderColor, autoComplete: autoComplete, multiline: multiline, inputRef: ref, SelectProps: {
        IconComponent: ArrowDownIcon,
        MenuProps: {
            PaperProps: {
                style: {
                    border: `1px solid ${theme.palette.primary.main}`,
                },
            },
        },
        ...SelectProps,
    }, "$padding": padding, InputProps: {
        ...InputProps,
        startAdornment: Icon ? (_jsx(FieldAdornment, { needSeparateLine: needSeparateLine, position: 'start', children: _jsx(Icon, {}) })) : (InputProps?.startAdornment),
    }, children: children })));
export default memo(InputFormField);
