import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { format } from 'date-fns';
import { useSubjectsNotes } from 'src/api/hooks/useSubjectsNotes';
import { settingIcon, testUserIcon } from 'src/assets';
import AwatingIcon from 'src/assets/icons/AwatingIcon';
import { Tooltip, Menu, MenuItem } from '@mui/material';
import { CardWrapper, DateText, Footer, Header, SettingImage, IconsBox, LastMessage, MemberIcon, MembersBox, Title, TitleWrapper } from './styles';
import SubjectNotes from '../SubjectNotes';
import SettingModal from '../GroupModal';
export const GroupItem = ({ subject }) => {
    const [listModalIsOpen, setListModalIsOpen] = useState(false);
    const [groupModalOpen, setGroupModalOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { text: title, id, members, date } = subject;
    const { data, updateData } = useSubjectsNotes(id.toString());
    const membersArray = members.split(',').filter((member) => member.trim() !== '');
    const renderMembers = () => membersArray.map((member, index) => (_jsx(Tooltip, { title: _jsxs("span", { children: ["Name", _jsx("br", {}), member] }), arrow: true, children: _jsx(MemberIcon, { src: testUserIcon }) }, index)));
    const formattedDate = data?.[0] ? format(new Date(data[0].date), 'HH:mm') : '';
    const handleButtonClick = () => {
        setGroupModalOpen(true);
    };
    const handleSettingClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setIsHovered(false);
    };
    const isPopupOpen = Boolean(anchorEl);
    const handleChangeArchiveStatus = () => {
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(CardWrapper, { onMouseEnter: () => setIsHovered(true), onMouseLeave: () => {
                    if (!isPopupOpen) {
                        setIsHovered(false);
                    }
                }, children: [_jsxs(Header, { children: [_jsxs(TitleWrapper, { children: [_jsx(Tooltip, { title: title, arrow: true, children: _jsx(Title, { onClick: handleButtonClick, children: title }) }), isHovered ? (_jsxs(_Fragment, { children: [_jsx(SettingImage, { src: settingIcon, alt: "Setting", onClick: handleSettingClick }), _jsx(Menu, { anchorEl: anchorEl, open: isPopupOpen, onClose: handleClose, sx: {
                                                    "& ul": {
                                                        padding: "0px !important"
                                                    },
                                                }, PaperProps: {
                                                    style: {
                                                        width: '150px',
                                                        padding: "0",
                                                        borderRadius: "4px"
                                                    },
                                                }, children: _jsx(MenuItem, { sx: {
                                                        color: "#007bff",
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        padding: "5px",
                                                        "&:hover": {
                                                            backgroundColor: "#fff",
                                                        },
                                                        "&:focus": {
                                                            backgroundColor: "#fff",
                                                        },
                                                    }, onClick: handleChangeArchiveStatus, children: "Move to Archive" }) })] })) : (_jsx(DateText, { children: formattedDate }))] }), data?.[0] ? (_jsx(LastMessage, { onClick: () => setListModalIsOpen(true), children: `${data[0].openUserName}: ${data[0].text}` })) : (_jsx(LastMessage, { onClick: () => setListModalIsOpen(true), children: "No message" }))] }), _jsxs(Footer, { children: [_jsx(MembersBox, { onClick: handleButtonClick, children: renderMembers() }), _jsxs(IconsBox, { children: [_jsx(AwatingIcon, { color: "#ABA7AF" }), _jsx(AwatingIcon, { color: "#ABA7AF" }), _jsx(AwatingIcon, { color: "#FF4848" }), _jsx(AwatingIcon, { color: "#FF4848" })] })] })] }), listModalIsOpen && (_jsx(SubjectNotes, { handleMessagUpdate: updateData, subjectName: title, subjectId: id.toString(), modalIsOpen: listModalIsOpen, setModalIsOpen: setListModalIsOpen })), groupModalOpen && (_jsx(SettingModal, { date: date, members: membersArray, subjectName: title, subjectId: id.toString(), modalIsOpen: groupModalOpen, setModalIsOpen: setGroupModalOpen }))] }));
};
