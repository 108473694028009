import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserThinIcon } from 'src/assets/icons';
import { MenuItem, Tooltip } from '@mui/material';
import ProfileIcon from 'src/assets/icons/Profile';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarIcon from 'src/assets/icons/Calendar';
import { Wrapper, SignUpTitle, ErrorMessage } from './styled';
import { defaultValues, registerScheme } from './lib/validation';
import InputFormField from '@/ui/InputFormField';
import SubmitButton from '@/ui/SubmitButton';
const SignMacAdress = () => {
    const [loading, setLoading] = useState(false);
    const [coords, setCoords] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const { handleSubmit, control, setValue, formState: { errors, isValid },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
     } = useForm({
        defaultValues,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        resolver: zodResolver(registerScheme),
    });
    const submit = useCallback(async (data) => {
        if (!isValid)
            return;
        setLoading(true);
    }, [isValid]);
    const loaderId = useId();
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                if ('geolocation' in navigator) {
                    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                        if (result.state === 'granted' || result.state === 'prompt') {
                            navigator.geolocation.getCurrentPosition((position) => {
                                const { latitude, longitude } = position.coords;
                                setCoords([latitude, longitude]);
                                setErrorMessage('');
                            }, (error) => {
                                setErrorMessage(`Error getting GPS data:  ${error}`);
                            });
                        }
                    });
                }
                else {
                    setErrorMessage(`Geolocation is not supported by this browser.`);
                }
            }
            catch (error) {
                setErrorMessage(`Error fetching initial data: ${error}`);
            }
        };
        fetchInitialData();
    }, [setValue]);
    return (_jsxs(Wrapper, { onSubmit: handleSubmit(submit), "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: [_jsx(SignUpTitle, { children: "Sign Mac Address" }), _jsx(Controller, { name: 'username', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: UserThinIcon, error: !!errors.username, helperText: errors.username?.message, autoComplete: 'name', placeholder: 'Name', autoFocus: true })) }), _jsx(Controller, { name: 'gender', control: control, render: ({ field: { ref, ...field } }) => (_jsxs(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: ProfileIcon, select: true, error: !!errors.gender, helperText: errors.gender?.message, placeholder: 'Gender', children: [_jsx(MenuItem, { value: 'Male', children: "Male" }), _jsx(MenuItem, { value: 'Female', children: "Female" })] })) }), _jsx(Controller, { name: 'dateOfBirth', control: control, render: ({ field: { ref, ...field } }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(DesktopDatePicker, { ...field, inputRef: ref, inputFormat: 'dd/MM/yyyy', renderInput: ({ inputProps, ...params }) => (_jsx(Tooltip, { placement: 'top-end', title: 'ddMMyyyy', arrow: true, disableInteractive: true, children: _jsx(InputFormField, { ...params, fullWidth: true, Icon: CalendarIcon, error: !!errors.dateOfBirth, autoComplete: 'new-password', inputProps: { ...inputProps, placeholder: 'Date of birth' }, helperText: errors.dateOfBirth?.message }) })) }) })) }), _jsx(Controller, { name: 'phone', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, onChange: (event) => {
                        onChange(event);
                    }, error: !!errors.phone, helperText: errors.phone?.message, autoComplete: 'phone', placeholder: 'Phone' })) }), _jsx(Controller, { name: 'mac', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, error: !!errors.mac, helperText: errors.mac?.message, autoComplete: 'mac', placeholder: 'MAC Address', autoFocus: true })) }), coords ? (_jsx(_Fragment, { children: _jsx(SubmitButton, { loaderId: loaderId, loading: loading, width: 292, height: 48, fontWeight: 900, "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: "Send" }) })) : (_jsx(_Fragment, {})), errorMessage && !coords ? _jsx(ErrorMessage, { children: errorMessage }) : null] }));
};
export default memo(SignMacAdress);
