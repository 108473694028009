import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-modal';
import { groupCloseIcon } from 'src/assets';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ModalContent, ButtonConainer, BackButton, SaveButton, } from './styled';
import { CloseButton, CloseImage, ErrorText, InputContainer, InputField, InputLabel, Title } from '../../globalStyled';
export default function GroupEditModal({ modalIsOpen, setModalIsOpen, subjectName, }) {
    const { register, handleSubmit, formState: { errors }, setFocus, reset, } = useForm();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (modalIsOpen) {
                setFocus("name");
            }
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [modalIsOpen, setFocus]);
    useEffect(() => {
        if (modalIsOpen) {
            reset({ name: subjectName });
        }
    }, [modalIsOpen, reset, subjectName]);
    const handleCloseModal = () => setModalIsOpen(false);
    const onSubmit = () => {
        handleCloseModal();
        reset({ name: "" });
    };
    return (_jsx(Modal, { isOpen: modalIsOpen, style: {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                position: 'absolute',
                top: '20%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '508px',
                height: '246px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '32px',
            },
        }, onRequestClose: handleCloseModal, children: _jsxs(ModalContent, { children: [_jsx(CloseButton, { onClick: handleCloseModal, children: _jsx(CloseImage, { src: groupCloseIcon }) }), _jsx(Title, { children: "Group Info" }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(InputContainer, { children: [_jsx(InputLabel, { children: "Group name" }), _jsx(InputField, { placeholder: "Group Name", ...register('name', { required: 'Required field' }) }), errors.name && _jsx(ErrorText, { children: errors.name.message })] }), _jsxs(ButtonConainer, { children: [_jsx(BackButton, { type: 'button', onClick: handleCloseModal, children: "Cancel" }), _jsx(SaveButton, { type: 'submit', children: "Save Changes" })] })] })] }) }));
}
