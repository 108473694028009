import { useState, useEffect } from 'react';
const useIsMobile = (maxWidth = 450) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
        setIsMobile(mediaQuery.matches);
        const handleResize = () => {
            setIsMobile(mediaQuery.matches);
        };
        mediaQuery.addListener(handleResize);
        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, [maxWidth]);
    return isMobile;
};
export default useIsMobile;
