import { useState, useEffect, useCallback } from 'react';
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};
export const useHasScrolledToBottom = ({ onReachBottom, debounceDelay = 200, } = {}) => {
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const [isFirstScroll, setIsFirstScroll] = useState(true);
    const handleScroll = useCallback(debounce(() => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const atBottom = scrollTop + windowHeight >= documentHeight - 1;
        if (atBottom && !hasScrolledToBottom) {
            setHasScrolledToBottom(true);
            if (onReachBottom && !isFirstScroll) {
                onReachBottom();
            }
            if (isFirstScroll) {
                setIsFirstScroll(false);
            }
        }
        else if (!atBottom && hasScrolledToBottom) {
            setHasScrolledToBottom(false);
        }
    }, debounceDelay), [hasScrolledToBottom, onReachBottom, debounceDelay]);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);
};
