import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography, } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { bg, mainLogo } from 'src/assets';
import { useSolrmLogin } from 'src/api/hooks/useSolrmLogin';
import { BackgroungImage, loginStyles, LogoBox, MainLogoImage, Page, PaperForm } from './styled';
import useIsMobile from '@/hooks/useIsMobile';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import useDocumentTitle from '@/hooks/useDocumentTitle';
const SolrmLogin = () => {
    const { register, handleSubmit, formState: { errors }, setError, reset, } = useForm();
    useDocumentTitle('SolRM IQ');
    const isMobile = useIsMobile();
    const location = useLocation();
    const invalidToken = location.state?.invalidToken;
    const emailInputRef = useRef(null);
    const [rememberEmail, setRememberEmail] = useState(false);
    const [rememberPassword, setRememberPassword] = useState(false);
    const loginMutation = useSolrmLogin(setError, invalidToken);
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const emailParam = queryParams.get('email');
        const savedEmail = localStorage.getItem('savedEmail');
        const savedPassword = localStorage.getItem('savedPassword');
        if (emailParam) {
            reset({ email: emailParam, password: '' });
        }
        else if (savedEmail || savedPassword) {
            reset({ email: savedEmail || '', password: savedPassword || '' });
            setRememberEmail(!!savedEmail);
            setRememberPassword(!!savedPassword);
        }
    }, [reset]);
    useEffect(() => {
        emailInputRef.current?.focus();
        if (invalidToken) {
            StorageService.remove(STORAGE_KEYS.token);
        }
    }, [invalidToken]);
    const onSubmit = (data) => {
        if (rememberEmail) {
            localStorage.setItem('savedEmail', data.email);
        }
        else {
            localStorage.removeItem('savedEmail');
        }
        if (rememberPassword) {
            localStorage.setItem('savedPassword', data.password);
        }
        else {
            localStorage.removeItem('savedPassword');
        }
        loginMutation.mutate(data);
    };
    return (_jsxs(Page, { children: [_jsxs(PaperForm, { component: 'form', elevation: 5, onSubmit: handleSubmit(onSubmit), children: [_jsx(Box, { width: '100%', children: _jsx(Typography, { sx: loginStyles.title, children: "IQ" }) }), _jsxs(Box, { sx: loginStyles.formContainer, children: [_jsx(TextField, { label: 'Email', ...register('email', { required: 'Required field' }), type: 'email', variant: 'filled', fullWidth: true, error: !!errors.email, helperText: errors.email?.message, sx: loginStyles.inputField }), _jsx(TextField, { label: 'Password', type: 'password', ...register('password', { required: 'Required field' }), variant: 'filled', fullWidth: true, error: !!errors.password, helperText: errors.password?.message, sx: loginStyles.inputField }), _jsx(Button, { type: 'submit', disabled: loginMutation.isPending, variant: 'contained', sx: loginStyles.loginButton, children: loginMutation.isPending ? _jsx(CircularProgress, { size: 22, color: 'inherit' }) : 'Login' }), _jsxs(Box, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: rememberEmail, onChange: (e) => setRememberEmail(e.target.checked), sx: {
                                                '&.Mui-checked': {
                                                    color: '#de1a55',
                                                },
                                            } }), label: 'Remember Email' }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: rememberPassword, onChange: (e) => setRememberPassword(e.target.checked), sx: {
                                                '&.Mui-checked': {
                                                    color: '#de1a55',
                                                },
                                            } }), label: 'Remember Password' })] })] })] }), !isMobile && (_jsxs(LogoBox, { children: [_jsx(BackgroungImage, { src: bg }), _jsx(MainLogoImage, { src: mainLogo })] }))] }));
};
export default memo(SolrmLogin);
