import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-modal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSubjectsNotes } from 'src/api/hooks/useSubjectsNotes';
import { closeIcon } from 'src/assets';
import { ModalContent, ModalTitle, CloseButton, CloseImage, HeaderConatiner, NotesWrapper, } from './styled';
import { NoteItem } from '../NoteItem';
import { AddNoteFormFromChat } from '@/pages/IQNarative/forms/AddNoteFormFromChat';
export default function SubjectNotes({ modalIsOpen, setModalIsOpen, subjectId, subjectName, handleMessagUpdate }) {
    const [isBottom, setIsBottom] = useState(false);
    const { data, loadMore, updateData } = useSubjectsNotes(subjectId);
    const notesWrapperRef = useRef(null);
    useEffect(() => {
        if (isBottom) {
            loadMore();
            setIsBottom(false);
        }
    }, [isBottom, loadMore]);
    useEffect(() => {
        if (handleMessagUpdate) {
            handleMessagUpdate();
        }
    }, [data, handleMessagUpdate]);
    const handleLoadMore = useCallback(() => {
        setIsBottom(true);
    }, []);
    const onScroll = useCallback(() => {
        if (notesWrapperRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = notesWrapperRef.current;
            if (scrollTop * -1 + clientHeight >= scrollHeight - 5) {
                handleLoadMore();
            }
        }
    }, [handleLoadMore]);
    return (_jsx(Modal, { isOpen: modalIsOpen, style: {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                position: 'absolute',
                top: '70px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '70%',
                height: '90vh',
                backgroundColor: '#fff',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                maxWidth: '90%',
                overflow: 'hidden',
            },
        }, onRequestClose: () => {
            setModalIsOpen(false);
        }, children: _jsxs(ModalContent, { children: [_jsxs(HeaderConatiner, { children: [_jsx(ModalTitle, { children: subjectName }), _jsx(CloseButton, { onClick: () => {
                                setModalIsOpen(false);
                            }, children: _jsx(CloseImage, { src: closeIcon }) })] }), _jsx(NotesWrapper, { ref: notesWrapperRef, onScroll: onScroll, children: data.map((item) => (_jsx(NoteItem, { note: item }, item.id))) }), _jsx(AddNoteFormFromChat, { subjectId: subjectId, handleSecsessAdding: updateData })] }) }));
}
