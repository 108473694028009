import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useRef } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { useParams } from 'react-router-dom';
import { buttonStyle, Page } from './styled';
import { useStudentInfo } from './lib/api/StudentInfo/useStudentInfo';
import { StudentAnswersTable } from './components/StudentAnswersTable';
import { LOCATIONS } from './components/StudentAnswersTable/constants';
const Student = () => {
    const { loading, studentData } = useStudentInfo();
    const printRef = useRef(null);
    const { studentId } = useParams();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: '⠀',
    });
    const convertImageToBase64 = (url) => fetch(url)
        .then((response) => response.blob())
        .then((blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }));
    const exportToExcel = async () => {
        if (studentData?.questions) {
            const totals = studentData.questions.reduce((acc, { Duration, Trying, HintsUsed }) => {
                acc.duration += Duration;
                acc.tries += Trying;
                acc.hintsUsed += HintsUsed;
                return acc;
            }, { duration: 0, tries: 0, hintsUsed: 0 });
            const safeTotal = (value) => (Number.isNaN(value) ? 0 : value);
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Student Answers');
            worksheet.columns = [
                { header: 'Question', key: 'question', width: 50 },
                { header: 'Location', key: 'location', width: 20 },
                { header: 'Duration', key: 'duration', width: 15 },
                { header: 'Number of tries', key: 'tries', width: 15 },
                { header: 'Hints Used', key: 'hintsUsed', width: 15 },
                { header: 'Focus Time', key: 'focusTime', width: 40 },
            ];
            const imagePromises = studentData.questions.map(({ Mikum }) => {
                const locationImage = LOCATIONS[Mikum];
                if (locationImage) {
                    return convertImageToBase64(locationImage);
                }
                return Promise.resolve(null);
            });
            const images = await Promise.all(imagePromises);
            worksheet.addRows([
                ...studentData.questions.map(({ Question, Duration, HintsUsed, Trying, Looked }, index) => ({
                    question: Question,
                    location: '',
                    duration: Duration,
                    tries: Trying,
                    hintsUsed: HintsUsed,
                    focusTime: Looked,
                    image: images[index],
                })),
                {
                    question: 'Total',
                    duration: safeTotal(totals.duration),
                    tries: safeTotal(totals.tries),
                    hintsUsed: safeTotal(totals.hintsUsed),
                },
            ]);
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
            const { lastRow } = worksheet;
            if (lastRow) {
                lastRow.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });
            }
            const { rowCount } = worksheet;
            worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
                if (rowNumber !== 1 && rowNumber !== rowCount) {
                    row.height = 50;
                    row.eachCell((cell, colNumber) => {
                        cell.alignment = {
                            vertical: 'middle',
                            horizontal: colNumber === 1 ? 'justify' : 'center',
                        };
                    });
                }
            });
            studentData.questions.forEach((_, index) => {
                const image = images[index];
                if (image) {
                    const imageId = workbook.addImage({
                        base64: image,
                        extension: 'jpeg',
                    });
                    worksheet.addImage(imageId, {
                        tl: { col: 1, row: index + 1 },
                        ext: { width: 160, height: 90 },
                    });
                }
            });
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, `StudentAnswers-${studentId}.xlsx`);
        }
    };
    return (_jsx(Page, { ref: printRef, id: 'print-page', children: loading ? (_jsx(CircularProgress, {})) : (studentData && (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, gap: '20px', children: Array.isArray(studentData.questions) ? (_jsx(StudentAnswersTable, { answers: studentData.questions })) : (_jsx(Box, { component: 'section', width: '100%', children: _jsx(Typography, { variant: 'h5', children: "Questions not found" }) })) }), _jsxs(Box, { mt: 2, children: [_jsx(Button, { onClick: handlePrint, style: buttonStyle, children: "Print" }), _jsx(Button, { onClick: exportToExcel, style: buttonStyle, children: "Export Excel" })] })] }))) }));
};
export default memo(Student);
