import styled from 'styled-components';
export const AddGroupFormWrapper = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;
export const SubmitButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 48px;
  background-color: #007bff;
  border: none;
  color: var(--IQ-Main, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 4px;
  padding: 0;
  transition: all 0.2s ease;

  &:active {
    background-color: #0056b3;
  }
`;
