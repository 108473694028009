import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import { ROUTES } from '@/constants/routes';
const login = async (data) => {
    const response = await axiosInstanceSolrm.post('api/VR/login', {
        Email: data.email.trim(),
        Password: data.password.trim(),
    });
    if (!response || response.data.Id === 0 || response.data.Result !== 1) {
        throw new Error('Invalid email or password');
    }
    return response.data;
};
export const useSolrmLogin = (setError, invalidToken) => {
    const navigate = useNavigate();
    return useMutation({
        mutationKey: ['login-solrm'],
        mutationFn: login,
        onSuccess: (data) => {
            StorageService.set(STORAGE_KEYS.token, data.Id);
            if (invalidToken) {
                window.history.replaceState({}, document.title);
            }
            navigate(ROUTES.subjects.path);
        },
        onError: () => {
            setError('email', { type: 'manual', message: 'Invalid email or password' });
            setError('password', { type: 'manual', message: 'Invalid email or password' });
        },
    });
};
