export const generateHousesSelectorData = (amount) => {
    const houses = [];
    for (let i = 0; i < amount; i++) {
        houses.push({
            name: `${i + 1}`,
            id: `${i + 1}`,
        });
    }
    return houses;
};
