import { createTheme } from '@mui/material/styles';
export const pxToVw = (px) => `${px * (100 / document.documentElement.clientWidth)}vw`;
export const { typography: { pxToRem }, } = createTheme();
export const THEME_VALUES = {
    header: {
        room: {
            height: '73px',
        },
        main: {
            height: '78px',
        },
    },
};
export const THEME_FONT_FAMILIES = {
    primary: 'Poppins, sans-serif',
    secondary: null,
};
export const THEME_FONT_WEIGHTS = {
    regular: 400,
    medium: 500,
    bold: 700,
    extraBold: 800,
};
export const THEME_PALETTE = {
    primary: {
        main: '#A041FF',
        light: '#BB97FF',
    },
    secondary: {
        main: '#72707D',
    },
    backgroundPrimary: {
        main: '#FFF',
    },
    backgroundSecondary: {
        main: '#B086FF',
    },
    lightPurple: {
        main: '#BB97FF',
    },
    defaultIcon: {
        main: '#ADABC0',
    },
    darkGray: {
        main: '#39393A',
    },
    tablesBackground: {
        main: '#CACACA',
    },
    white: {
        main: '#FFF',
    },
    hint: {
        main: '#7E7A93',
    },
    helper: {
        main: '#979797',
    },
    red: {
        main: '#F04747',
    },
    black: {
        main: '#000',
    },
    lightBlack: {
        main: '#333238',
    },
    purple: {
        main: '#6945FA',
    },
    online: {
        main: '#36BF7D',
    },
    offline: {
        main: '#8F8F8F',
    },
    inactive: {
        main: '#8F8F8F',
    },
    error: {
        main: '#FF4848',
    },
    primarySnackbar: {
        main: '#f0474759',
    },
    secondarySnackbar: {
        main: '#B086FF',
    },
};
export const THEME_BOX_SHADOWS = {
    default: '0px 12px 30px black',
};
const theme = createTheme({
    palette: { ...THEME_PALETTE },
    boxShadow: THEME_BOX_SHADOWS,
    spacing: 2,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 950,
            lg: 1250,
            xl: 1536,
        },
    },
    values: THEME_VALUES,
    util: {
        pxToRem,
        pxToVw,
    },
    font: {
        family: THEME_FONT_FAMILIES,
        weight: THEME_FONT_WEIGHTS,
    },
    typography: {
        fontFamily: THEME_FONT_FAMILIES.primary,
        allVariants: {
            fontFamily: THEME_FONT_FAMILIES.primary,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
    },
});
export default theme;
