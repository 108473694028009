import { object, string, array } from 'zod';
export const validationSchema = object({
    name: string({
        required_error: 'Required field',
    }).min(1, 'Required field'),
    members: array(string()).nonempty("Members list can't be empty"),
});
export const defaultValues = {
    name: '',
    members: [],
};
