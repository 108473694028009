import { isAxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { STORAGE_KEYS } from '@/constants/common';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
import StorageService from '@/utils/storage';
const useVerifyUser = () => {
    const [verifying, setVerifying] = useState(true);
    const [verified, setVerified] = useState(false);
    useEffect(() => {
        const verify = async () => {
            const token = StorageService.get(STORAGE_KEYS.token, false);
            if (!token) {
                setVerifying(false);
                setVerified(false);
                return;
            }
            const verifyToken = await axiosInstance.post('ApiCall', {
                p: RequestService.buildRequest('BO_User_GetPrmFromToken', token),
            });
            if (!verifyToken || isAxiosError(verifyToken) || !Array.isArray(verifyToken.data)) {
                setVerifying(false);
                setVerified(false);
                return;
            }
            StorageService.set(STORAGE_KEYS.tokenData, verifyToken.data[0]);
            setVerifying(false);
            setVerified(true);
        };
        verify();
    }, []);
    return [verifying, verified];
};
export default useVerifyUser;
