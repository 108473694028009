import { useEffect, useRef, useState } from 'react';
import { parseNotesResponse } from '../lib/helpers/parseNotesResponse';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
export const NOTES_QUERY_KEY = 'get-notes';
const getNotes = async (subjectId, lastItemC) => {
    try {
        const token = StorageService.get(STORAGE_KEYS.token, false);
        const { data } = await axiosInstanceSolrm.post('/api/VR/ApiNotesCall', {
            p: `N_Get_SubjectNotes~${token}~${subjectId}~${lastItemC}`,
        });
        const notes = parseNotesResponse(JSON.parse(data) ?? []);
        return notes;
    }
    catch (error) {
        console.error('Error fetching notes:', error);
        return [];
    }
};
export const useSubjectsNotes = (subjectId) => {
    const [dataList, setDataList] = useState([]);
    const [isEndData, setIsEndData] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);
    const [isRefetching, setIsRefetching] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const page = useRef(1);
    const lastIndex = useRef(0);
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const newData = await getNotes(subjectId, lastIndex.current);
            if (newData.length > 0) {
                lastIndex.current = newData.at(-1)?.id ?? lastIndex.current;
                setDataList((prev) => [...prev, ...newData]);
                if (newData.length < 10) {
                    setIsEndData(true);
                }
            }
            else {
                setIsEndData(true);
            }
            setIsLoadingInitial(false);
            setIsFetching(false);
        }
        catch (error) {
            console.error('Error fetching notes:', error);
            setError('Failed to fetch notes.');
            setIsFetching(false);
        }
    };
    const loadMore = async () => {
        if (!isEndData && !isRefetching) {
            page.current += 1;
            fetchData();
        }
    };
    useEffect(() => {
        if (isLoadingInitial) {
            fetchData();
        }
    }, [isLoadingInitial]);
    const updateData = async () => {
        setIsRefetching(true);
        const newData = await getNotes(subjectId, 0);
        if (newData.length > 0) {
            const latestNoteId = newData.at(-1)?.id ?? 0;
            lastIndex.current = latestNoteId;
            setDataList((prev) => {
                const existingIds = new Set(prev.map((note) => note.id));
                const uniqueNewData = newData.filter((note) => !existingIds.has(note.id));
                return [...prev, ...uniqueNewData].sort((a, b) => b.id - a.id);
            });
            if (newData.length < 10) {
                setIsEndData(true);
            }
        }
        else {
            setIsEndData(true);
        }
        setIsRefetching(false);
    };
    return {
        data: dataList,
        error,
        isFetching,
        isLoadingInitial,
        isRefetching,
        updateData,
        loadMore,
        isEndData,
    };
};
