import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useVerifyUser from './hooks/useVerifyUser';
import { ROUTES } from '@/constants/routes';
const ProtectedRoute = () => {
    const [verifying, verified] = useVerifyUser();
    if (verifying) {
        return _jsx(CircularProgress, { color: 'primary' });
    }
    if (!verified) {
        return _jsx(Navigate, { to: ROUTES.login.path, replace: true, state: { invalidToken: true } });
    }
    return _jsx(Outlet, {});
};
export default memo(ProtectedRoute);
