export function createRows(donates) {
    return donates.map((item, i) => ({
        type: 'MASTER',
        id: i + 1,
        date: item.date,
        expanded: false,
    }));
}
export function rowKeyGetter(row) {
    return row.id;
}
