import { useMutation } from '@tanstack/react-query';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
export const ADD_SUBJECT_MUTATION_KEY = 'add-subject';
const addSubject = async ({ name, emails }) => {
    const token = StorageService.get(STORAGE_KEYS.token, false);
    const emailsString = emails?.length ? emails.join(';') : '';
    const parameterString = `N_Add_Subject~${token}~${name}~${emailsString}`;
    console.log(emails);
    const response = await axiosInstanceSolrm.post('/api/VR/ApiNotesCall', {
        p: parameterString,
    });
    return response.data;
};
export const useAddSubject = () => useMutation({
    mutationKey: [ADD_SUBJECT_MUTATION_KEY],
    mutationFn: addSubject,
});
