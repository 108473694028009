import { Paper } from '@mui/material';
import styled from 'styled-components';
export const Page = styled.div `
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 400px 1fr;

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;
export const PaperForm = styled(Paper) `
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  gap: 16px;

  position: relative;
`;
export const LogoBox = styled.div `
  position: relative;
  width: 100%;
  height: 100vh;
`;
export const FormLogo = styled.img `
  height: 400px;
  width: 100%;
  margin-top: -110px;
`;
export const BackgroungImage = styled.img `
  height: 100%;
  width: 100%;
  z-index: 1;
  object-fit: cover;
  position: absolute;
`;
export const MainLogoImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;

  z-index: 2;
  position: absolute;
`;
export const loginStyles = {
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        borderRadius: '6px',
        padding: '0 30px',
    },
    inputField: {
        '& .MuiFilledInput-root': {
            backgroundColor: 'white',
        },
        '& .MuiFormLabel-root': {
            color: 'black',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'black',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
        },
    },
    loginButton: {
        minWidth: '100px',
        borderRadius: 0,
        backgroundColor: '#de1a55',
        width: '100%',
        height: '40px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#de1a55',
            opacity: 0.8,
        },
        '&:active': {
            backgroundColor: '#de1a55',
            opacity: 0.6,
        },
    },
    title: {
        fontSize: '46px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#de1a55',
        margin: '45px 0',
    },
};
