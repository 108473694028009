import styled, { keyframes } from 'styled-components';
export const CloseButton = styled.button `
  border: none;
  background-color: transparent;
  position: fixed;
  top: 16px;
  right: 16px;
`;
export const CloseImage = styled.img `
  width: 16px;
  height: 16px;
`;
export const Title = styled.h2 `
  color: var(--Grayscale-Black, #1a141f);
  text-align: center;

  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
`;
export const ErrorText = styled.span `
  color: red;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  bottom: -20px;
  left: 0;
`;
export const InputLabel = styled.span `
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
export const InputField = styled.input `
  padding: 12px 12.5px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#007bff')};
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  outline: none;
  color: #333238;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InputContainer = styled.div `
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
export const ChosenMemberWrapper = styled.div `
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const DropListInputField = styled.input `
  border: none;
  font-size: 16px;
  width: 100%;
  outline: none;
  color: #333238;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InputWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${({ isFocused }) => (isFocused ? '1px solid #d4d2d5' : 'none')};
  padding-bottom: ${({ isFocused }) => (isFocused ? '12px' : '0px')};
  margin-bottom: ${({ isFocused }) => (isFocused ? '12px' : '0px')};
`;
export const ListContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: height 0.3s ease;
`;
export const ListWrapper = styled.div `
  padding: 12px 12.5px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#007bff')};
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  outline: none;
  position: relative;
  overflow: hidden;

  height: ${({ isFocused }) => (isFocused ? '180px' : '49px')};
  padding-bottom: ${({ isFocused }) => (isFocused ? '0px' : '`12px')};

  transition: height 0.3s ease;
`;
export const MembersWrapper = styled.div `
  margin-top: 6px;
  display: flex;
  gap: 10px;
  height: ${({ isFocused }) => (isFocused ? '115px' : '0px')};

  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
export const SpinnerContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
export const spinAnimation = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div `
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spinAnimation} 1s linear infinite;
`;
