import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { Suspense, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Main, Wrapper } from './styled';
import ErrorBoundary from '../ErrorBoundary';
import Header from '@/components/Header';
import { solrmRoutes } from '@/constants/routes';
const Layout = () => {
    const { pathname } = useLocation();
    const isSolrmPage = solrmRoutes.includes(pathname);
    return (_jsx(ErrorBoundary, { children: _jsxs(Wrapper, { id: 'main-layout', children: [_jsx(Header, {}), _jsx(Main, { bgColor: isSolrmPage ? "#F3F9FF" : undefined, id: 'main', children: _jsx(Suspense, { fallback: _jsx(CircularProgress, { color: 'primary' }), children: _jsx(Outlet, {}) }) })] }) }));
};
export default memo(Layout);
