import styled from 'styled-components';
export const mapStyles = {
    width: '100%',
    height: '100vh',
};
export const Toolbar = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
`;
export const ControlButton = styled.button `
  margin: 0 10px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-color: #007bff;
  color: white;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
export const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
