export const STORAGE_KEYS = {
    token: 'token',
    tokenData: 'tokenData',
    userId: 'userId',
    userName: 'userName',
    email: 'emailVRLogin',
    rememberMe: 'rememberMe',
    enteredStudents: 'enteredStudents',
    password: 'password',
};
