import { jsx as _jsx } from "react/jsx-runtime";
import 'react-data-grid/lib/styles.css';
import { useEffect, useMemo } from 'react';
import DataGrid from 'react-data-grid';
export default function DonateDataGrid({ data }) {
    const rows = data.map((item, index) => ({
        ...item,
        id: (index + 1).toString(),
    }));
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    const columns = useMemo(() => [
        {
            key: 'id',
            name: 'ID',
            width: 100,
        },
        {
            key: 'date',
            name: 'Date',
        },
        {
            key: 'countryName',
            name: 'Country name',
        },
        {
            key: 'accountNo',
            name: 'Account No',
        },
        {
            key: 'customerName',
            name: 'Customer name',
        },
    ], []);
    return _jsx(DataGrid, { columns: columns, rows: rows });
}
