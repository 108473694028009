import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useEffect, useState } from 'react';
import maplibregl, { Marker } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useDonate } from 'src/api/hooks/useDonateData';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper, ControlButton, Toolbar, mapStyles } from './styled';
import { extractCoordinates, handleMapBoundsAndCenterCoords } from './lib/helpers';
import { Spinner, SpinnerContainer } from '../DonateGiver/styled';
import AddDonation from '../AddDonation';
import { ROUTES } from '@/constants/routes';
const DonatesMap = () => {
    const mapContainer = useRef(null);
    const { data, loading, reloadData } = useDonate();
    const navigate = useNavigate();
    const [markers, setMarkers] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useEffect(() => {
        setMarkers(extractCoordinates(data));
    }, [data]);
    const token = process.env.STADIA_MAPS_API_KEY;
    const styleUrl = `https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=${token}`;
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    useEffect(() => {
        const map = new maplibregl.Map({
            container: mapContainer.current,
            style: styleUrl,
            center: handleMapBoundsAndCenterCoords(markers),
            zoom: 4,
        });
        if (markers.length !== 0) {
            markers.forEach(({ longitudes, latitudes }) => {
                new Marker().setLngLat([longitudes, latitudes]).addTo(map);
            });
        }
        return () => map.remove();
    }, [markers, styleUrl]);
    function openModal() {
        setModalIsOpen(true);
    }
    const navigateToMap = () => {
        navigate(ROUTES.donateGiverPage.path, {});
    };
    return (_jsxs(ContentWrapper, { children: [_jsxs(Toolbar, { children: [_jsx(ControlButton, { onClick: openModal, children: "Add donation" }), _jsx(ControlButton, { onClick: navigateToMap, children: "Donate list" })] }), _jsx("div", { ref: mapContainer, style: mapStyles }), _jsx(SpinnerContainer, { children: loading && _jsx(Spinner, {}) }), _jsx(AddDonation, { handleSubmite: reloadData, modalIsOpen: modalIsOpen, setModalIsOpen: setModalIsOpen })] }));
};
export default DonatesMap;
