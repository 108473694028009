import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { memo, useCallback } from 'react';
import SubmitButtonStyled from './styled';
import useDisableTimeout from '../../lib/hooks/useDisableTimeout';
const SubmitButton = ({ loading = false, loaderId, disabled = false, uppercase, onSubmit, children, type = 'submit', timeout = 500, variant = 'contained', ...props }) => {
    const [wasClicked, disable] = useDisableTimeout(timeout);
    const handleSubmit = useCallback((event) => {
        disable();
        onSubmit?.(event);
    }, [disable, onSubmit]);
    return (_jsx(SubmitButtonStyled, { "$uppercase": uppercase, type: type, disabled: disabled || loading || wasClicked, onSubmit: handleSubmit, variant: variant, ...props, children: loading || wasClicked ? _jsx(CircularProgress, { id: loaderId, size: 25 }) : children }));
};
export default memo(SubmitButton);
