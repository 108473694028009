import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Box, Button, ListItem, Popover } from '@mui/material';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IQLogo, testUserIcon } from 'src/assets';
import { CustomButton, Logo, SearchWrapper, UserIcon } from './styled';
import { ItemButton, ListStyled } from '../styled';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import { CardTypePicker } from '@/ui/CardTypePicker';
import usePopoverAnchor from '@/hooks/usePopoverAnchor';
import { ROUTES } from '@/constants/routes';
const SolrmHeader = () => {
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    const anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    };
    useDocumentTitle('SolRM IQ');
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };
    const handleSingOut = () => {
        navigate(ROUTES.solrmLogin.path);
        handleClose();
    };
    const handleNavigateProfile = () => {
        handleClose();
    };
    return (_jsx(AppBar, { id: 'header', sx: {
            position: 'sticky',
            backgroundColor: 'white',
            height: "78px",
            paddingLeft: "32px",
            paddingRight: "32px",
            boxShadow: 'none',
            borderBottom: '1px solid #E0E0E0',
            width: "100%"
        }, children: _jsxs(Box, { sx: {
                height: "78px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between"
            }, children: [_jsxs(Box, { sx: { flexDirection: "row", display: "flex", alignItems: "center" }, children: [_jsx(Button, { "aria-label": 'Go back', onClick: handleGoBack, sx: { padding: 0, marginTop: '12px', height: "fit-content", marginRight: "44px" }, children: _jsx(Logo, { src: IQLogo, alt: 'IQ-Narrative' }) }), _jsx(CardTypePicker, {}), _jsx(SearchWrapper, { id: 'input-form-field-portal' })] }), _jsxs(Box, { sx: { flexDirection: "row", display: "flex", gap: "10px" }, children: [_jsx(Box, { id: 'print-button-portal' }), _jsxs(Box, { children: [_jsx(CustomButton, { onClick: handleOpen, children: _jsx(UserIcon, { src: testUserIcon }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, children: _jsxs(ListStyled, { children: [_jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleNavigateProfile, children: "Profile" }) }), _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleSingOut, children: "Sign Out" }) })] }) })] })] })] }) }));
};
export default memo(SolrmHeader);
