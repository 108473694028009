export function extractCoordinates(donates) {
    const markers = [];
    donates.forEach((donate) => {
        const lat = parseFloat(donate.latitudes);
        const lon = parseFloat(donate.longitudes);
        if (!Number.isNaN(lat) && !Number.isNaN(lon)) {
            markers.push({ latitudes: lat, longitudes: lon });
        }
    });
    return markers;
}
export const handleMapBoundsAndCenterCoords = (markers) => {
    if (markers.length === 0) {
        return [0, 0];
    }
    const longitudes = markers.map((marker) => marker.longitudes);
    const latitudes = markers.map((marker) => marker.latitudes);
    const minLongitudes = Math.min(...longitudes);
    const minLatitudes = Math.min(...latitudes);
    const maxLongitudes = Math.max(...longitudes);
    const maxLatitudes = Math.max(...latitudes);
    return [(minLongitudes + maxLongitudes) / 2, (minLatitudes + maxLatitudes) / 2];
};
