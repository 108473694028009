import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { MenuItem, Select, FormControl } from "@mui/material";
import { ListItemText, PaperStyled } from "./styled";
import ArrowDownBlue from "../../assets/icons/ArrowDownBlue";
const cardTypes = [
    { id: "awaitingReply", name: "Awaiting Reply" },
    { id: "noReplyNeeded", name: "No Reply Needed" },
    { id: "archived", name: "Archived" },
];
export const CardTypePicker = () => {
    const [selectedType, setSelectedType] = useState(cardTypes[0]);
    const handleChange = (event) => {
        const selectedCardType = cardTypes.find(card => card.id === event.target.value);
        if (selectedCardType) {
            setSelectedType(selectedCardType);
        }
    };
    const styles = {
        formControl: {
            border: "none !important",
            outline: "none !important",
        },
        select: {
            width: "184px",
            height: "40px",
            border: "1px solid #007bff",
            borderRadius: "4px !important",
            background: "#fff",
            "& fieldset": {
                border: "none !important",
            },
            "& .MuiSelect-select": {
                color: "#007bff",
                padding: "8px 12px",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "150%",
                border: "none !important",
                outline: "none !important",
            },
            "& .MuiSelect-icon": {
                right: 7,
            },
        },
    };
    return (_jsx(FormControl, { sx: styles.formControl, children: _jsx(Select, { labelId: "card-type-select-label", value: selectedType.id, onChange: handleChange, IconComponent: ArrowDownBlue, MenuProps: {
                PaperProps: {
                    component: PaperStyled,
                },
            }, sx: styles.select, children: cardTypes.map(({ id, name }) => (_jsx(MenuItem, { value: id, children: _jsx(ListItemText, { children: name }) }, id))) }) }));
};
