import { useMutation } from '@tanstack/react-query';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
export const ADD_NOTE_MUTATION_KEY = 'add-note';
const addNote = async ({ text, subjectId }) => {
    const token = StorageService.get(STORAGE_KEYS.token, false);
    const response = await axiosInstanceSolrm.post('/api/VR/ApiNotesCall', {
        p: `N_Add_Note~${token}~${text}~${subjectId}`,
    });
    return response.data;
};
export const useAddNote = () => useMutation({
    mutationKey: [ADD_NOTE_MUTATION_KEY],
    mutationFn: addNote,
});
