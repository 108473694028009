import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useDonate } from 'src/api/hooks/useDonateData';
import { useNavigate } from 'react-router-dom';
import { ControlButton, Page, RootContainer, Spinner, SpinnerContainer, Toolbar } from './styled';
import MobileDonateDataGrid from './component/MobileDonateDataGrid';
import DonateDataGrid from './component/DonateDataGrid';
import AddDonation from '../AddDonation';
import { ROUTES } from '@/constants/routes';
export default function DonateGiverPage() {
    const { data, reloadData, loading } = useDonate();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
        const handleResize = () => {
            setIsMobile(mediaQuery.matches);
        };
        mediaQuery.addListener(handleResize);
        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);
    function openModal() {
        setModalIsOpen(true);
    }
    const navigateToMap = () => {
        navigate(ROUTES.donatesMapPage.path, {});
    };
    const navigateToSingMac = () => {
        navigate(ROUTES.singMacAdress.path, {});
    };
    return (_jsxs(Page, { children: [_jsxs(Toolbar, { children: [_jsx(ControlButton, { onClick: openModal, children: "Add donation" }), _jsx(ControlButton, { onClick: navigateToMap, children: "Map" }), _jsx(ControlButton, { onClick: navigateToSingMac, children: "Sing MAC Address" })] }), _jsxs(RootContainer, { children: [isMobile ? _jsx(MobileDonateDataGrid, { data: data }) : _jsx(DonateDataGrid, { data: data }), _jsx(SpinnerContainer, { children: loading && _jsx(Spinner, {}) })] }), _jsx(AddDonation, { handleSubmite: reloadData, modalIsOpen: modalIsOpen, setModalIsOpen: setModalIsOpen })] }));
}
