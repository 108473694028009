import styled from 'styled-components';
export const CardWrapper = styled.div `
  display: flex;
  width: 363.812px;
  min-height: 129px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  background: #fff;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 1300px) {
    width: 300px;
  }

  @media (max-width: 1100px) {
    width: 250px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const Header = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
export const TitleWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
  max-height: 24px;
`;
export const Footer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 31px;
`;
export const MembersBox = styled.div `
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;
export const IconsBox = styled.div `
  display: flex;
  gap: 2px;
`;
export const Title = styled.h2 `
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
`;
export const LastMessage = styled.p `
  overflow: hidden;
  color: #8f8f8f;
  text-overflow: ellipsis;
  font-family: Poppins;
  word-break: break-all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
export const DateText = styled.p `
  color: #8f8f8f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  transition: opacity 0.3s ease;
`;
export const MemberIcon = styled.img `
  width: 31px;
  height: 31px;
  border-radius: 100px;
  border: 3px solid #fff;
  margin-left: -10px;
`;
export const SettingImage = styled.img `
  width: 24px;
  height: 24px;
  transition: opacity 0.3s ease;
`;
