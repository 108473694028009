import { useEffect, useRef, useState } from 'react';
import { parseUsersResponse } from '../lib/helpers/parseUsersResponse';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
export const SUBJECTS_QUERY_KEY = 'get-users';
const getSubjects = async (lastId, searchString) => {
    try {
        const token = StorageService.get(STORAGE_KEYS.token, false);
        const { data } = await axiosInstanceSolrm.post('/api/VR/ApiWebCall', {
            p: `N_Get_MyUsers~${token}~${lastId}~${searchString}`,
        });
        const users = parseUsersResponse(JSON.parse(data) ?? []);
        return users;
    }
    catch (error) {
        console.error('Error fetching users:', error);
        return [];
    }
};
export const useUsers = (initialSearchString) => {
    const [dataList, setDataList] = useState([]);
    const [isEndData, setIsEndData] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);
    const [isRefetching, setIsRefetching] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [searchString, setSearchString] = useState(initialSearchString ?? ' ');
    const page = useRef(1);
    const lastIndex = useRef(0);
    const prevData = useRef([]);
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const newData = await getSubjects(lastIndex.current, searchString ?? '');
            lastIndex.current = newData.at(-1)?.id ?? 0;
            if (newData.length === 0 || newData.length < 6) {
                setIsEndData(true);
            }
            if (page.current === 1) {
                setDataList(newData);
            }
            else {
                setDataList((prev) => [...prev, ...newData]);
            }
            prevData.current = dataList;
            setIsLoadingInitial(false);
            setIsFetching(false);
        }
        catch (error) {
            console.error('Error fetching subjects:', error);
            setError('Failed to fetch subjects.');
            setIsFetching(false);
        }
    };
    const loadMore = async () => {
        if (!isEndData && !isRefetching) {
            page.current += 1;
            fetchData();
        }
    };
    useEffect(() => {
        if (isLoadingInitial) {
            fetchData();
        }
    }, [isLoadingInitial]);
    const updateData = async () => {
        setDataList([]);
        setIsRefetching(true);
        lastIndex.current = 0;
        page.current = 1;
        setIsEndData(false);
        await fetchData();
        setIsRefetching(false);
    };
    const loadAll = () => {
        while (!isEndData) {
            loadMore();
        }
    };
    useEffect(() => {
        if (searchString || searchString === '') {
            updateData();
        }
    }, [searchString]);
    return {
        data: dataList,
        error,
        isFetching,
        isLoadingInitial,
        isRefetching,
        updateData,
        loadMore,
        loadAll,
        isEndData,
        setSearchString,
    };
};
