import styled from 'styled-components';
export const ControlButton = styled.button `
  margin: 0 10px;
  margin-top: 5px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  align-self: center;
  color: white;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  width: 40px;
  height: 40px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
