import styled from 'styled-components';
export const CardWrapper = styled.div `
  padding: 5px 20px 5px 0px;
  margin-left: 20px;
  background-color: #ffffff;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-bottom: 1px solid black;
`;
export const ItemWrapper = styled.div ``;
export const Title = styled.p `
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  margin: 0;
`;
export const Text = styled.p `
  font-size: 15px;
  word-wrap: break-word;
  color: #000000;
  margin: 0;
`;
export const DateText = styled.p `
  font-size: 14px;
  color: gray;
  margin: 0;
`;
export const MemberTitle = styled.p `
  font-size: 15px;
  color: #808080;
  margin: 0;
  margin-bottom: 5px;
`;
