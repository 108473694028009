import { object, string } from 'zod';
export const validationSchema = object({
    country: string({
        required_error: 'Required field',
    }).min(1, 'Required field'),
    houses: string({
        required_error: 'Required field',
    }).min(1, 'Required field'),
});
export const defaultValues = {
    country: '',
    houses: '',
};
export const houseAmount = 20;
export const BUTTON_TEXT = 'Add donate';
export const MODAL_TITLE = 'Add donation';
