import { useEffect, useState } from 'react';
import { axiosInstanceSolrm } from '@/env/http';
async function fetchData() {
    try {
        const response = await axiosInstanceSolrm.post('api/VR/ApiWebCall', {
            p: 'Web_Get_Donations_Countries',
        });
        const countriesData = JSON.parse(response.data);
        const countries = countriesData.map((country) => ({
            id: country.C.toString(),
            name: country.Name,
        }));
        return countries;
    }
    catch (error) {
        console.error('Error fetching countries:', error);
        return [];
    }
}
export const useCountryes = () => {
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchCountries = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const fetchedCountries = await fetchData();
                setCountries(fetchedCountries);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchCountries();
    }, []);
    return { countries, isLoading, error };
};
