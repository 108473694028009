import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';
export const Page = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100dvh - 60px);
  justify-content: center;
  align-items: center;
`;
export const PaperForm = styled(Paper) `
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max(500px, 40vw);
  gap: 16px;
  padding: 20px;
  border-radius: 8px;

  margin-bottom: 200px;

  ${({ theme }) => css `
    ${theme.breakpoints.down('sm')} {
      width: 90vw;
    }
  `}
`;
