import styled from 'styled-components';
export const Logo = styled.img `
  max-height: 38px;
  max-width: 188px;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
export const ControlButton = styled.button `
  padding: 8px 16px;
  height: 60px;
  width: 100px;
  cursor: pointer;
  border: none;

  background-color: white;
  align-self: center;
  color: black;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border: 1px solid black;

  &:hover {
    background-color: gray;
  }
  &:active {
    background-color: darkgray;
  }
`;
export const SearchWrapper = styled.div `
  margin-left: 143px;
  transition: margin-left 0.3s ease;

  & input {
    padding: 0 !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    width: 240px;
    color: #9fa2ab;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  & .MuiInputBase-root {
    border-radius: 8px !important;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    outline: none !important;
    padding: 0 !important;
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  @media (max-width: 900px) {
    margin-left: 63px;

    & input {
      width: 120px;
    }
  }

  @media (max-width: 1200px) {
    & input {
      width: 200px;
    }
  }

  @media (min-width: 1400px) {
    margin-left: 300px;
  }
`;
export const CustomButton = styled.button `
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.3s ease;
  border-radius: 5px;
  border: 3px solid transparent;

  display: flex;
  gap: 4px;
`;
export const UserIcon = styled.img `
  width: 46px;
  height: 46px;
  object-fit: contain;
  border-radius: 100px;
  border: 1px solid gray;
`;
