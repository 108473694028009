import { Paper, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
export const PaperStyled = styled(Paper)(() => css `
    position: relative;
    width: 184px;
    border: 1px solid #007bff;
  `);
export const ListItemText = styled(Typography)(() => css `
    color: #007bff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  `);
