import { useEffect, useRef, useState } from 'react';
import { parseSubjectResponse } from '../lib/helpers/parseSubjectResponse';
import { axiosInstanceSolrm } from '@/env/http';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
export const SUBJECTS_QUERY_KEY = 'get-subjects';
const getSubjects = async (lastItemC, searchString) => {
    const token = StorageService.get(STORAGE_KEYS.token, false);
    try {
        const { data } = await axiosInstanceSolrm.post('/api/VR/ApiNotesCall', {
            p: `N_Get_Subjects~${token}~${lastItemC}~${searchString}`,
        });
        return parseSubjectResponse(JSON.parse(data) ?? []);
    }
    catch (error) {
        console.error('Error fetching subjects:', error);
        return [];
    }
};
export const useSubjects = (searchString) => {
    const [dataList, setDataList] = useState([]);
    const [isEndData, setIsEndData] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const page = useRef(1);
    const lastIndex = useRef(0);
    const handleError = (error) => {
        console.error('Error fetching subjects:', error);
        setError('Failed to fetch subjects.');
    };
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const newData = await getSubjects(lastIndex.current, searchString ?? '');
            lastIndex.current = newData.at(-1)?.id ?? 0;
            setDataList((prev) => {
                const updatedData = page.current === 1 ? newData : [...prev, ...newData];
                if (newData.length === 0 || newData.length < 10) {
                    setIsEndData(true);
                }
                return updatedData;
            });
        }
        catch (error) {
            handleError(error);
        }
        finally {
            setIsLoadingInitial(false);
            setIsFetching(false);
        }
    };
    const loadMore = () => {
        if (!isEndData && !isRefetching) {
            page.current += 1;
            fetchData();
        }
    };
    useEffect(() => {
        setIsLoadingInitial(true);
        fetchData();
    }, []);
    const updateData = async () => {
        setDataList([]);
        setIsRefetching(true);
        lastIndex.current = 0;
        page.current = 1;
        setIsEndData(false);
        await fetchData();
        setIsRefetching(false);
    };
    useEffect(() => {
        if (searchString !== undefined) {
            const timer = setTimeout(updateData, 500);
            return () => clearTimeout(timer);
        }
    }, [searchString]);
    return {
        data: dataList,
        error,
        isFetching,
        isLoadingInitial,
        isRefetching,
        updateData,
        loadMore,
        isEndData,
    };
};
