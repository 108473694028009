import { useEffect, useState } from 'react';
import { parseDonateData } from '../lib/helpers/parseDonateData';
import { axiosInstanceSolrm } from '@/env/http';
import { useUser } from '@/hooks/useUserData';
import RequestService from '@/utils/request';
async function fetchData(giverId) {
    try {
        const response = await axiosInstanceSolrm.post('api/VR/ApiWebCall', {
            p: RequestService.buildRequest('Web_Get_Giver_Accounts', giverId),
        });
        if (!response.data?.length) {
            return [];
        }
        const donateData = JSON.parse(response.data);
        return parseDonateData(donateData);
    }
    catch (error) {
        console.error(error);
        return [];
    }
}
export const useDonate = () => {
    const { userId } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [requestError, setError] = useState(null);
    const fetchDataAndHandleErrors = async () => {
        try {
            setLoading(true);
            const result = await fetchData(userId);
            setData(result);
        }
        catch (error) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchDataAndHandleErrors();
    }, [userId]);
    const reloadData = async () => {
        await fetchDataAndHandleErrors();
    };
    return { data, loading, requestError, reloadData };
};
