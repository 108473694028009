import styled from 'styled-components';
export const ModalContent = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
export const SaveButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 48px;

  background-color: #007bff;
  border: none;

  color: var(--IQ-Main, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 4px;
  padding: 0;
  transition: all 0.2s ease;

  &:active {
    background-color: #0056b3;
  }
`;
export const MembersForm = styled.form `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;
