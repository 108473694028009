import { Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
export const Page = styled.div `
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;
  background-color: #f3f9ff;
`;
export const Toolbar = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  padding-left: 10px;
  width: 100%;
`;
export const SpinnerContainer = styled.div `
  position: ${({ isEmpty }) => (isEmpty ? 'fixed' : 'static')};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: ${({ isEmpty }) => (isEmpty ? '50%' : 'unset')};
  top: ${({ isEmpty }) => (isEmpty ? '50%' : 'unset')};
  left: 0;
  right: 0;
  transform: ${({ isEmpty }) => (isEmpty ? 'translateY(-50%)' : 'none')};
  margin: ${({ isEmpty }) => (isEmpty ? '0' : '0 auto')};
`;
export const spinAnimation = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div `
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spinAnimation} 1s linear infinite;
`;
export const ControlButton = styled.button `
  cursor: pointer;
  border: none;
  height: 30px;
  background-color: transparent;
  background-color: #007bff;
  align-self: center;
  color: white;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
export const AddControlButton = styled.button `
  cursor: pointer;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  background: #007bff;
  color: white;
  outline: none;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 48px;
  bottom: 38px;

  color: var(--White, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
export const RootContainer = styled.div `
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  height: min-content;
  width: 80%;
  padding: 20px;
  gap: 10px;
  align-self: center;

  @media (max-width: 1400px) {
    width: 90%;
  }

  @media (max-width: 1500px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const SubjectWrapper = styled.div `
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
export const NoDataText = styled(Typography) `
  font-size: 25px;
  color: gray;
  margin-top: 10px;
  text-align: center;
  width: 100%;
`;
export const ErrorText = styled(Typography) `
  font-size: 25px;
  color: gray;
  margin-top: 10px;
`;
