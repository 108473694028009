import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle `
  html {
    scroll-behavior: smooth;
  }

  body {
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }

	ul, li, ol {
		list-style: none;
    margin: 0;
    padding: 0;
	}

  dt, dd {
    display: inline-block;
  }

  dd {
    margin-left: 7px;
  }

  dl {
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  @page {
    size: auto;
    margin: 40px;
  }


  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;

      & .print-none {
        display: none;
      }

      & thead {
        display: inline-table;
      }

      & th, td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }

      & section {
        box-shadow: none !important;
        border: 1px solid black !important;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        gap: 12px !important;
      }
    }

    #main {
      margin-top: 60px;
      overflow: unset !important;
    }

    #print-page {
      padding-bottom: 0;
    }
  }
`;
