import styled from 'styled-components';
export const ModalTitle = styled.p `
  margin: 0;
  font-weight: 'normal';
  font-size: 20px;
  line-height: 18px;
`;
export const ModalContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  height: 100%;
`;
export const HeaderConatiner = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding-bottom: 5px;
  border-bottom: 1px solid black;
`;
export const CloseButton = styled.button `
  border: none;
  background-color: transparent;
`;
export const CloseImage = styled.img `
  height: 20px;
  width: 20px;
`;
export const ControlButton = styled.button `
  margin: 0 10px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  height: 30px;
  background-color: transparent;
  background-color: #007bff;
  align-self: center;
  color: white;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  width: 292px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
export const NotesWrapper = styled.div `
  display: flex;

  flex-direction: column-reverse;
  align-items: center;
  padding-top: 5px;
  padding-right: 15px;
  height: 100%;
  overflow-y: auto;
`;
export const SpinnerContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
