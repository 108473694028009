import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InputFormField from '@/ui/InputFormField';
export const TextInput = () => {
    const { setValue, setError, formState: { errors, isSubmitSuccessful }, } = useFormContext();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const emailInputRef = useRef(null);
    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
        setName('');
        setNameError('');
    }, [isSubmitSuccessful]);
    useEffect(() => {
        if (errors.text) {
            setNameError(errors.text.message || '');
        }
    }, [errors]);
    const handleChange = (e) => {
        const { value } = e.target;
        setName(value);
        setValue('text', value);
        setNameError('');
        if (value === '') {
            setError('text', { type: 'manual', message: 'Name is required' });
        }
    };
    return (_jsx(InputFormField, { multiline: true, fullWidth: true, value: name, autoComplete: 'off', inputRef: emailInputRef, onChange: handleChange, error: !!nameError, helperText: nameError }));
};
