import styled from 'styled-components';
export const NameText = styled.h6 `
  font-family: 'Poppins';
  color: #1a141f;

  font-size: 18px;
  font-weight: 400;

  margin: 0;
`;
