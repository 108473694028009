import { date, object, string } from 'zod';
const macAddressSchema = string({
    required_error: 'Mac address is required',
}).regex(/^\b(?:[0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2}\b$/, 'Invalid MAC address');
export const registerScheme = object({
    username: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    dateOfBirth: date({
        required_error: 'Required field',
        invalid_type_error: 'Required field',
    })
        .min(new Date('1900-01-01'), { message: 'Too old!' })
        .max(new Date(), { message: 'invalid date!' })
        .refine((dateOfBirth) => {
        const today = new Date();
        const age = today.getFullYear() - dateOfBirth.getFullYear();
        const monthDiff = today.getMonth() - dateOfBirth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
            return age - 1 >= 18;
        }
        return age >= 18;
    }, { message: 'You must be at least 18 years old' }),
    gender: string({
        required_error: 'Required field',
    }).min(1, 'Required field'),
    phone: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .transform((value) => value.trim()),
    mac: macAddressSchema,
});
export const defaultValues = {
    username: '',
    gender: '',
    dateOfBirth: null,
    phone: '',
    mac: '',
};
