import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, memo, useCallback } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityOffIcon from 'src/assets/icons/VisibilityOff';
import VisibilityIcon from 'src/assets/icons/Visibility';
import { DefaultTextField } from '../styled';
const PasswordField = ({ InputProps, ...rest }) => {
    const [type, setType] = useState('password');
    const isPassword = type === 'password';
    const handleClick = useCallback(() => setType(isPassword ? 'text' : 'password'), [isPassword]);
    return (_jsx(DefaultTextField, { ...rest, type: type, InputProps: {
            ...InputProps,
            endAdornment: (_jsxs(_Fragment, { children: [InputProps?.endAdornment, _jsx(InputAdornment, { position: 'end', children: _jsx(IconButton, { color: 'inactive', size: 'small', onClick: handleClick, "aria-label": isPassword ? 'Show password' : 'Hide password', children: isPassword ? _jsx(VisibilityOffIcon, {}) : _jsx(VisibilityIcon, {}) }) })] })),
        } }));
};
export default memo(PasswordField);
