import styled, { keyframes } from 'styled-components';
export const Page = styled.div `
  height: calc(100% - 60px);
`;
export const Toolbar = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
`;
export const SpinnerContainer = styled.div `
  position: fixed;
  bottom: 15px;
  align-self: center;
`;
export const spinAnimation = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div `
  border: 4px solid #f3f3f3;
  border-top: 4px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spinAnimation} 1s linear infinite;
`;
export const ControlButton = styled.button `
  margin: 0 10px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-color: #007bff;
  color: white;
  outline: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;
export const RootContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100%;

  gap: 10px;

  > .rdg {
    height: calc(100vh - 120px);

    @media (max-width: 500px) {
      height: calc(100vh - 140px);
    }

    @media (max-width: 1000px) and (max-height: 500px) {
      height: calc(100vh - 140px);
    }
  }
`;
