import { useEffect, useState } from 'react';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
import { STORAGE_KEYS } from '@/constants/common';
import StorageService from '@/utils/storage';
async function fetchData(token) {
    try {
        const response = await axiosInstance.post('ApiCall', {
            p: RequestService.buildRequest('BO_User_GetPrmFromToken', token),
        });
        return response.data[0].Nm;
    }
    catch (error) {
        console.error('Error fetching:', error);
        return '';
    }
}
export const useGetUserNameEng = () => {
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = StorageService.get(STORAGE_KEYS.token, false);
    useEffect(() => {
        const fetchCountries = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const fetchedName = await fetchData(token);
                setName(fetchedName);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchCountries();
    }, [token]);
    return { name, isLoading, error };
};
