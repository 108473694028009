import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { useCurrentClass } from 'src/api/hooks/useCurrentClass';
import { Page } from './styled';
import DateService from '@/utils/date';
const CurrentClassPage = () => {
    const { data, isError, isLoading } = useCurrentClass();
    if (isLoading)
        return _jsx(CircularProgress, { size: 22, color: 'inherit' });
    if (isError || !data)
        return _jsx("div", { children: "Error loading" });
    return (_jsx(Page, { children: _jsx(TableContainer, { sx: {
                border: '1px solid rgb(224, 224, 224)',
                maxHeight: '100%',
                width: '100%',
                overflowX: 'hidden',
            }, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: ['Date', 'Time', 'Student Name', 'Device Number', 'Current Q', 'Question'].map((header) => (_jsx(TableCell, { sx: { fontWeight: 600, color: 'white', backgroundColor: 'grey' }, align: header === 'Date' || header === 'Question' ? 'left' : 'center', width: header === 'Date' ? '10%' : header === 'Question' ? '40%' : 'auto', children: header }, header))) }) }), _jsx(TableBody, { children: data.map(({ StartSession, DeviceNumber, CurrentQ, Question, StudetName }) => {
                            const [date, time] = DateService.getFullDate(new Date(StartSession));
                            return (_jsxs(TableRow, { sx: {
                                    cursor: 'pointer',
                                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                                }, hover: true, children: [_jsx(TableCell, { sx: { maxWidth: '50px' }, children: date }), _jsx(TableCell, { sx: { maxWidth: '100px' }, align: 'center', children: time }), _jsx(TableCell, { sx: { minWidth: '100px' }, align: 'center', children: StudetName }), _jsx(TableCell, { sx: { minWidth: '100px' }, align: 'center', children: DeviceNumber }), _jsx(TableCell, { sx: { minWidth: '100px' }, align: 'center', children: CurrentQ }), _jsx(TableCell, { sx: { minWidth: '100px' }, children: Question })] }, DeviceNumber));
                        }) })] }) }) }));
};
export default memo(CurrentClassPage);
