import { useQuery } from '@tanstack/react-query';
import { STORAGE_KEYS } from '@/constants/common';
import StorageService from '@/utils/storage';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
const CURRENT_CLASS_QUERY_KEY = 'current-class';
const fetchData = async () => {
    const token = StorageService.get(STORAGE_KEYS.token, false);
    const response = await axiosInstance.post('ApiCall', {
        p: RequestService.buildRequest('Eng_BO_Get_CurrentClass', token),
    });
    return Array.isArray(response.data) ? response.data : [];
};
export const useCurrentClass = () => useQuery({
    queryKey: [CURRENT_CLASS_QUERY_KEY],
    queryFn: fetchData,
    refetchInterval: 2000,
});
