import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
export const useStudentInfoApi = () => {
    const getStudentQuestions = (testId) => axiosInstance.post('ApiCall', {
        p: RequestService.buildRequest('Eng_BO_Get_Student_Questions', testId),
    });
    return {
        getStudentQuestions,
    };
};
