import { useState, useCallback, useEffect } from 'react';
const useDisableTimeout = (timeout) => {
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        const id = setTimeout(() => {
            if (disabled) {
                setDisabled(false);
            }
        }, timeout);
        return () => clearTimeout(id);
    }, [disabled, timeout]);
    const disable = useCallback(() => {
        setDisabled(true);
    }, []);
    return [disabled, disable];
};
export default useDisableTimeout;
