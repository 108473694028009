import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { groupCloseIcon } from 'src/assets';
import { useAddSubject } from 'src/api/hooks/useAddSubject';
import { useUsers } from 'src/api/hooks/useUsers';
import { useForm } from 'react-hook-form';
import { debounce } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitButton, AddGroupFormWrapper, } from './styled';
import { defaultValues, validationSchema } from './lib/constants';
import ChosenMember from '../MembersEditModal/ui/ChosenMember';
import { MemberItem } from '../../ui/MemberItem';
import { ChosenMemberWrapper, CloseButton, CloseImage, DropListInputField, ErrorText, InputContainer, InputField, InputLabel, InputWrapper, ListContainer, ListWrapper, MembersWrapper, Spinner, SpinnerContainer, Title } from '../../globalStyled';
export default function AddGroupForm({ modalIsOpen, setModalIsOpen, handleSubmit: onSubmit, }) {
    const { register, handleSubmit, formState: { errors }, reset, setValue, setFocus, } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues,
    });
    const { mutate, isPending } = useAddSubject();
    const { data, setSearchString, isFetching, loadMore } = useUsers(' ');
    const [searchValue, setSearchValue] = useState('');
    const [chosenMembers, setChosenMembers] = useState([]);
    const membersWrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [modalHeight, setModalHeight] = useState('339px');
    const handleInputFocus = () => {
        setIsInputFocused(true);
        setModalHeight('473px');
    };
    const handleInputBlur = () => {
        setIsInputFocused(false);
        setModalHeight('339px');
    };
    const handleCloseModal = () => {
        setModalIsOpen(false);
        handleInputBlur();
        reset({ name: '', members: [] });
        setChosenMembers([]);
    };
    const clearInput = () => {
        setSearchValue('');
        setSearchString('');
    };
    const handleScroll = () => {
        const wrapper = membersWrapperRef.current;
        if (wrapper) {
            const bottom = wrapper.scrollHeight - wrapper.scrollTop === wrapper.clientHeight;
            if (bottom && !isFetching) {
                loadMore();
            }
        }
    };
    const handleAddMember = (member) => {
        setChosenMembers((prevMembers) => [...prevMembers, member]);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    useEffect(() => {
        setValue('members', chosenMembers.map((member) => member.mail));
    }, [chosenMembers, setValue]);
    const availableMembers = data.filter((member) => !chosenMembers.some((chosen) => chosen.id === member.id.toString()));
    const debouncedSetSearchString = useCallback(debounce((value) => {
        setSearchString(value);
    }, 500), []);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (modalIsOpen) {
                setFocus("name");
            }
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [modalIsOpen, setFocus]);
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
        debouncedSetSearchString(e.target.value);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && searchValue === '' && chosenMembers.length > 0) {
            setChosenMembers((prevMembers) => prevMembers.slice(0, -1));
        }
    };
    const renderMembers = () => (_jsxs(MembersWrapper, { onScroll: handleScroll, ref: membersWrapperRef, isFocused: isInputFocused, children: [availableMembers.map((member, index) => (_jsx(MemberItem, { member: {
                    name: member.name,
                    mail: member.email,
                    groupId: '1',
                    imageIcon: '',
                    id: member.id.toString(),
                }, handleAddMember: () => handleAddMember({
                    name: member.name,
                    mail: member.email,
                    groupId: '1',
                    imageIcon: '',
                    id: member.id.toString(),
                }) }, index))), isFetching && (_jsx(SpinnerContainer, { children: _jsx(Spinner, {}) }))] }));
    const handleSubmitForm = async (formData) => {
        handleInputBlur();
        mutate({
            name: formData.name,
            emails: formData.members,
        }, {
            onSuccess() {
                onSubmit();
                handleCloseModal();
            },
        });
    };
    return (_jsxs(Modal, { isOpen: modalIsOpen, style: {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                position: 'absolute',
                top: '20%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '508px',
                height: modalHeight,
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '32px',
                transition: 'height 0.3s ease',
                overflow: 'hidden',
            },
        }, onRequestClose: handleCloseModal, children: [_jsx(CloseButton, { onClick: handleCloseModal, children: _jsx(CloseImage, { src: groupCloseIcon }) }), _jsx(Title, { children: "Create Group" }), _jsxs(AddGroupFormWrapper, { onSubmit: handleSubmit(handleSubmitForm), children: [_jsxs(InputContainer, { children: [_jsx(InputLabel, { children: "Group name" }), _jsx(InputField, { onFocus: handleInputBlur, placeholder: "Group Name", hasError: Boolean(errors.name), ...register('name', { required: 'Required field' }) }), errors.name && _jsx(ErrorText, { children: errors.name.message })] }), _jsxs(ListContainer, { onFocus: handleInputFocus, children: [_jsx(InputLabel, { children: "Add members" }), _jsxs(ListWrapper, { hasError: Boolean(errors.members), isFocused: isInputFocused, children: [_jsxs(InputWrapper, { isFocused: isInputFocused, children: [_jsx(ChosenMemberWrapper, { children: chosenMembers.map((member) => (_jsx(ChosenMember, { member: member }, member.id))) }), _jsx(DropListInputField, { placeholder: chosenMembers.length === 0 ? 'Member' : '', onChange: handleSearchChange, onKeyDown: handleKeyDown, value: searchValue, ref: inputRef }), searchValue.length !== 0 && (_jsx(CloseImage, { src: groupCloseIcon, onClick: clearInput }))] }), renderMembers()] }), errors.members && _jsx(ErrorText, { children: errors.members.message })] }), isPending ? (_jsx(SpinnerContainer, { children: _jsx(Spinner, {}) })) : (_jsx(SubmitButton, { type: "submit", children: "Create" }))] })] }));
}
