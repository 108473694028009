import { useState, useCallback } from 'react';
const usePopoverAnchor = (initialEl = null) => {
    const [anchorEl, setAnchorEl] = useState(initialEl);
    const handleOpen = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const open = !!anchorEl;
    return { open, anchorEl, setAnchorEl, handleOpen, handleClose };
};
export default usePopoverAnchor;
