import { axiosInstanceSolrm } from '@/env/http';
async function addDonate(userId, countryid, houses) {
    try {
        const response = await axiosInstanceSolrm.post('api/VR/ApiWebCall', {
            p: `Web_AddDonationAccount~${userId}~${countryid}~${houses}`,
        });
        return response;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return undefined;
    }
}
export const useAddDonate = () => ({ addDonate });
