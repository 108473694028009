import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSubjects } from 'src/api/hooks/useSubjects';
import { createPortal } from 'react-dom';
import Search from 'src/assets/icons/Search';
import { GroupItem } from './component/GroupItem';
import SubjectNotes from './component/SubjectNotes';
import { AddControlButton, ErrorText, NoDataText, Page, RootContainer, Spinner, SpinnerContainer, SubjectWrapper, } from './styled';
import AddGroupForm from '../../forms/AddGroupForm';
import InputFormField from '@/ui/InputFormField';
import { useHasScrolledToBottom } from '@/hooks/useScrolledToBottom';
export default function SubjectsPage() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [chatModalIsOpen, setChatModalIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const rootContainerRef = useRef(null);
    const { data, updateData, loadMore, isFetching, isEndData, error, isLoadingInitial, isRefetching } = useSubjects(searchValue);
    const openModal = () => setModalIsOpen(true);
    const handleSearchValueChange = (event) => {
        setSearchValue(event.target.value);
    };
    const inputFormFieldPortal = document.getElementById('input-form-field-portal');
    useHasScrolledToBottom({
        onReachBottom: () => {
            if (!isEndData && !isFetching) {
                loadMore();
            }
        },
    });
    useEffect(() => {
        if (!isLoadingInitial) {
            loadMore();
        }
    }, [isLoadingInitial]);
    useLayoutEffect(() => {
        const checkHeightAndLoadMore = () => {
            if (rootContainerRef.current) {
                const rootContainerHeight = rootContainerRef.current.offsetHeight;
                const viewportHeight = window.innerHeight;
                if (rootContainerHeight < viewportHeight - 70 && !isEndData && !isFetching) {
                    loadMore();
                }
            }
        };
        checkHeightAndLoadMore();
        window.addEventListener('resize', checkHeightAndLoadMore);
        return () => {
            window.removeEventListener('resize', checkHeightAndLoadMore);
        };
    }, [isEndData, isFetching]);
    const renderSearchField = () => inputFormFieldPortal &&
        createPortal(_jsx(InputFormField, { value: searchValue, placeholder: 'Search', needSeparateLine: false, Icon: Search, onChange: handleSearchValueChange }), inputFormFieldPortal);
    const renderContent = () => {
        if (isLoadingInitial || isRefetching) {
            return (_jsx(SpinnerContainer, { isEmpty: data.length === 0, children: _jsx(Spinner, {}) }));
        }
        if (error) {
            return _jsxs(ErrorText, { children: ["Server Error: ", error] });
        }
        if (!data.length) {
            return _jsx(NoDataText, { children: "No data from server" });
        }
        return (_jsxs(_Fragment, { children: [_jsx(RootContainer, { ref: rootContainerRef, children: data.map((item) => (_jsx(SubjectWrapper, { children: _jsx(GroupItem, { subject: item }) }, item.id))) }), isFetching && (_jsx(SpinnerContainer, { children: _jsx(Spinner, {}) })), _jsx(SubjectNotes, { subjectName: data[0]?.text, subjectId: data[0]?.id.toString(), modalIsOpen: chatModalIsOpen, setModalIsOpen: setChatModalIsOpen, handleMessagUpdate: () => { } })] }));
    };
    const handleSubmit = () => {
        updateData();
        setChatModalIsOpen(true);
    };
    return (_jsxs(Page, { children: [renderSearchField(), renderContent(), _jsx(AddControlButton, { onClick: openModal, children: "+" }), _jsx(AddGroupForm, { handleSubmit: handleSubmit, modalIsOpen: modalIsOpen, setModalIsOpen: setModalIsOpen })] }));
}
