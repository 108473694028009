import { jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
export const AddNoteFormFormProvider = ({ children }) => {
    const valigationSchema = yup.object().shape({
        text: yup.string().required('Required').max(3000, `Max 3000 characters allowed`),
    });
    const methods = useForm({
        defaultValues: {
            text: undefined,
        },
        resetOptions: {
            keepDefaultValues: true,
        },
        resolver: yupResolver(valigationSchema),
    });
    return _jsx(FormProvider, { ...methods, children: children });
};
