import { Autocomplete, Paper } from '@mui/material';
import styled, { css } from 'styled-components';
export const AutocompleteStyled = styled(Autocomplete) `
  & .MuiAutocomplete-inputRoot {
    padding-bottom: 43px;

    & .MuiAutocomplete-endAdornment {
      right: 7px;
    }
  }
`;
export const PaperStyled = styled(Paper)(({ theme }) => css `
    position: relative;
    width: 104%;
    right: 2%;
    border: 1px solid ${theme.palette.primary.main};
  `);
