import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetUserNameEng } from 'src/api/hooks/useGetUserNameEng';
import { NameText } from './styled';
import { ROUTES } from '@/constants/routes';
const Name = () => {
    const { pathname } = useLocation();
    const { name } = useGetUserNameEng();
    return pathname !== ROUTES.login.path && pathname !== ROUTES.main.path ? (_jsx(NameText, { children: name })) : null;
};
export default memo(Name);
